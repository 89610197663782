export const breakpoints = {
  tablet: 768,
  desktop: 992,
}

export const mq = {
  xs_mobile: '@media (max-width: 528px)',
  mobile: '@media (min-width: 529px) and (max-width: 768px)',
  tablet: '@media (min-width: 769px) and (max-width: 992px)',
}
