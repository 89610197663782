import React from 'react'
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'

import { useToken } from '../TokenContext'

const cache = new InMemoryCache({
  typePolicies: {
    TrendPeriod: {
      keyFields: false,
    },
    Facet: {
      keyFields: false,
    },
    Facets: {
      keyFields: false,
    },
    EventIdFacet: {
      keyFields: false,
    },
    EventPeriod: {
      keyFields: false,
    },
    MostFrequentEvent: {
      keyFields: false,
    },
  },
})

export const ApolloClientProvider: React.FC = ({ children }) => {
  const { authToken } = useToken()

  const client = new ApolloClient({
    connectToDevTools: true,
    cache: cache,
    link: new HttpLink({
      uri: process.env.REACT_APP_ASSET_FLEET_API,
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : '',
        'Content-Type': 'application/json',
      },
    }),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
