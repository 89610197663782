import styled from '@emotion/styled'

export const textColor = '#4B5457'
export const hoveredBackgroundColor = '#0076ff'

export const BrandImagePlaceholder = styled.div`
  width: 140px;
  height: 50px;
  margin: 45px 35px 20px;
  background-color: lightgrey;
`

export const BrandImage = styled.img`
  width: 100px;
  margin: 35px 0 25px 35px;
`
