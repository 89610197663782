import { useEffect } from 'react'

import { loadHotjar } from '../vendor/hotjar'
import useAccessToken from './useAccessToken'
import { canLoadAdditionalScripts } from './useOneTrust'
import useOptanonAlertBoxClosedPolling from './useOptanonAlertBoxClosedPolling'

const PUBLIC_KEY:string = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtDkhjY7l7kIf6YJxsru2
4Oke/uzMogtOQoyKSRAnLLfgEX9L6y5GhwOs/ZsIF6s3a7T37Hb00320GLJqjV07
ynKxnEkHVNiF50rlgiBH/Zy5xM+bX3t87QNyNTgu8HYcEOCCDrU3kseYcubA+x2W
bJk+SFmqk29Grt5XraYGhmp2Ohx5jAlQ4NZwpdh8NVAujcur7I6TTDJXLbvSZI42
jMjK0evFVweni3zEL7PQxvivaiksdT94LzSxPlGF8ZGr1fW+R4GDFgyrxOSkEJcg
ioOCZGWrJlipS0mUxZtqo7g22GxMC/OGL+GjAY3MOl/NQFL87UMIlY1GUX0DSeg0
kQIDAQAB
-----END PUBLIC KEY-----`

async function pemToCryptoKey(pemPublicKey: string) {
  // Convert the PEM public key to an ArrayBuffer
  const pemHeader = "-----BEGIN PUBLIC KEY-----";
  const pemFooter = "-----END PUBLIC KEY-----";
  const pemContent = pemPublicKey.replace(pemHeader, "").replace(pemFooter, "").replace(/\s+/g, "");
  const publicKeyData = base64StringToArrayBuffer(pemContent);

  // Import the public key as a CryptoKey object
  const cryptoKey = await window.crypto.subtle.importKey(
    "spki", // Format of the key: SubjectPublicKeyInfo
    publicKeyData,
    {
      name: "RSA-OAEP",
      hash: "SHA-256", // The hash algorithm used with the RSA-OAEP algorithm
    },
    true, // Whether the key is extractable
    ["encrypt"] // The key usages
  );

  return cryptoKey;
}

function base64StringToArrayBuffer(base64String: any) {
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  const bytes = new Uint8Array(buffer);
  const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
  return btoa(binary);
}

async function encryptEmailAddress(email: string): Promise<string> {
  const encoder = new TextEncoder();
  const encryptedEmail = await window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    await pemToCryptoKey(PUBLIC_KEY),
    encoder.encode(email)
  );

  return arrayBufferToBase64(encryptedEmail)
}


export default function useHotjar() {
  const token = useAccessToken()
  const isOptanonAlertBoxClosed = useOptanonAlertBoxClosedPolling()

  useEffect(() => {
    if (!canLoadAdditionalScripts() || !isOptanonAlertBoxClosed) {
      return
    }
    // This creates window.hj in production.
    loadHotjar(token, encryptEmailAddress)
  }, [token, isOptanonAlertBoxClosed])
}
