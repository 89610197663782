import styled from '@emotion/styled'

import { mq } from '../../constants/breakpoints'

export const GridContainer = styled.div`
  margin: auto;
  font-size: 16px;

  ${mq.tablet} {
    max-width: 700px;
  }

  ${mq.mobile} {
    max-width: 500px;
  }
`

export const Grid = styled.div<{
  rowCount: number
}>`
  display: grid;
  display: -ms-grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 100px;

  -ms-grid-columns: 1fr 20px 1fr 20px 1fr;
  -ms-grid-rows: ${({ rowCount }) => {
    let gridRows = '100px' // First row
    for (let i = 0; i < rowCount - 1; i++) {
      gridRows += ' 20px 100px' // Add other rows
    }
    return gridRows
  }};

  ${mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-columns: 1fr 20px 1fr;
  }

  ${mq.mobile} {
    grid-template-columns: repeat(1, 1fr);
    -ms-grid-columns: 1fr;
  }

  ${mq.xs_mobile} {
    grid-template-columns: repeat(1, 1fr);
    -ms-grid-columns: 1fr;
  }
`

export const GridTitle = styled.h2<{ textColor?: string }>`
  font-size: 1.2rem;
  margin: 40px 1px 10px;
  color: ${(props) => props.textColor || '#5b5b5b'};
`
