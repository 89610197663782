import moment from 'moment'

export const toStrShortDateFormat = (date: any) => {
  if (!date) return ''
  // TODO: replace with date-fns for filesize
  return moment(date).format('MMM D, YYYY')
}

export const toInputDateFormat = (date: string | undefined) => {
  if (!date) return ''
  return moment(date).format('YYYY-MM-DD')
}

export const toYearDateFormat = (date: any) => {
  if (!date) return ''
  // TODO: replace with date-fns for filesize
  return moment(date).format('YYYY')
}