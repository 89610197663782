import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const LoaderStyles = styled.div`
  text-align: center;
  color: #707070;
  font-size: 1.2em;

  svg {
    animation: ${rotate} 1s ease-in-out infinite;
  }
`
