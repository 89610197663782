import { useState, useEffect } from 'react'

function getCookieValue(name: string): string | null {
  console.log('getCookieValue')
  const cookieString: string = decodeURIComponent(document.cookie)
  const cookieParts: string | undefined = cookieString.split('; ').find(row => row.startsWith(`${name}=`))
  return cookieParts ? cookieParts.split('=')[1] : null
}

function useOptanonAlertBoxClosedPolling(intervalMs: number = 1000): boolean {
  const [isOptanonAlertBoxClosed, setIsOptanonAlertBoxClosed] = useState<boolean>(!!getCookieValue('OptanonAlertBoxClosed'))

  useEffect(() => {
    if (isOptanonAlertBoxClosed) return

    const intervalId = setInterval(() => {
      const alertBoxClosedCookie: string | null = getCookieValue('OptanonAlertBoxClosed')
      if (alertBoxClosedCookie) {
        console.log('alertBoxClosedCookie')
        setIsOptanonAlertBoxClosed(true)
        clearInterval(intervalId)
      }
    }, intervalMs)

    return () => clearInterval(intervalId)
  }, [isOptanonAlertBoxClosed, intervalMs])

  return isOptanonAlertBoxClosed
}

export default useOptanonAlertBoxClosedPolling
