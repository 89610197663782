import { useEffect } from 'react'

import { getEnv } from '../themes/helpers'

interface ScriptAttributes {
  src: string
  dataDomainScript: string
}

const isProduction = getEnv() === 'prod'

const domainScripts: Record<string, ScriptAttributes> = {
  'rmhoist': {
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    dataDomainScript: `8f4bdd35-4202-4e7b-b151-bb185a88004d${isProduction?'':'-test'}`
  },
  'verlinde': {
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    dataDomainScript: `877d6af9-79f8-41eb-8e91-f27b55d227b4${isProduction?'':'-test'}`
  },
  'donaticranes': {
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    dataDomainScript: `76923e19-b1db-4700-8594-1e7bcd7e000e${isProduction?'':'-test'}`
  },
  'swfkrantechnik': {
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    dataDomainScript: `f1973533-04ea-47d0-9c16-13c724cc092b${isProduction?'':'-test'}`
  },
}

export function canLoadAdditionalScripts(): boolean {
  const optanonConsentMatch = document.cookie.match(/OptanonConsent=([^;]+);?/)

  if (!optanonConsentMatch || optanonConsentMatch.length < 2) {
    console.log('Can\'t find or match OptanonConsent cookie.')
    return false
  }

  const decodedCookieValue = decodeURIComponent(optanonConsentMatch[1])

  const groupsRegex = /groups=([^&]+)/
  const groupsMatch = decodedCookieValue.match(groupsRegex)

  if (!groupsMatch || groupsMatch.length < 2) {
    return false
  }

  const groupSettings = groupsMatch[1].split(',')
  const hasAdditionalScriptsEnabled = groupSettings.some(group => {
    const [id, value] = group.split(':')
    return id !== 'C0001' && value === '1'
  })

  return hasAdditionalScriptsEnabled
}



const useOneTrust = () => {
  useEffect(() => {
    const extractDomainKeyword = (hostname: string): string | undefined => {
      const match = hostname.match(/(rmhoist|verlinde|donaticranes|swfkrantechnik)/)
      return match ? match[0] : undefined
    }

    const hostname: string = window.location.hostname
    const domainKeyword = extractDomainKeyword(hostname)
    const scriptAttributes: ScriptAttributes | undefined = domainKeyword ? domainScripts[domainKeyword] : undefined

    if (scriptAttributes) {
      const oneTrustScript = document.createElement('script')
      oneTrustScript.src = scriptAttributes.src
      oneTrustScript.type = 'text/javascript'
      oneTrustScript.charset = 'UTF-8'
      oneTrustScript.setAttribute('data-domain-script', scriptAttributes.dataDomainScript)
      oneTrustScript.setAttribute('data-document-language', 'true')


      const inlineScript = document.createElement('script')
      inlineScript.type = 'text/javascript'
      inlineScript.textContent = 'function OptanonWrapper() { }'

      document.body.appendChild(oneTrustScript)
      document.body.appendChild(inlineScript)

      return () => {
        document.body.removeChild(oneTrustScript)
        document.body.removeChild(inlineScript)
      }
    }
  }, [])
}

export default useOneTrust