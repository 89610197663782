import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { useHistory, NavLink, NavLinkProps } from 'react-router-dom'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { DrawerMenu as Drawer } from '@kc/alphabrands-component-library'

import ExternalLink from '../ExternalLink'
import Modal from '../Modal'
import { MenuLinkStyles, IconWrapper } from './DrawerMenuItems.styles'

// TS bug
// eslint-disable-next-line no-shadow
export enum MenuItemType {
  Link,
  Divider,
  Collapse,
}

type LinkProps = NavLinkProps & {
  to: string
  external?: boolean
  disabled?: boolean
  modalContent?: JSX.Element
}

const Link = ({ to, external, disabled, modalContent, children, ...navLinkprops }: LinkProps) => {
  const openInSameWindow = to.startsWith('http') && !external

  if (openInSameWindow || external || modalContent) {
    const target = openInSameWindow ? '_self' : '_blank'

    return !disabled && !modalContent ? (
      <ExternalLink to={to} target={target}>
        {children}
      </ExternalLink>
    ) : (
      // FIXME: Dirty workaround until link component rewritten.
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a onClick={navLinkprops.onClick}>{children}</a>
    )
  }

  return (
    <NavLink to={to} {...navLinkprops}>
      {children}
    </NavLink>
  )
}

// TODO: get types from Module
export interface DrawerMenuLinkProps {
  type: MenuItemType.Link
  to: string
  title: string
  disabled?: boolean
  external?: boolean
  showExternalIcon?: boolean
  IconComponent: React.FC<any>
  menuItemIconProps?: { width?: string }
  modalContent?: JSX.Element
}

// TODO: look into types and optional props
export const DrawerMenuLink: React.FC<DrawerMenuLinkProps> = ({
  to,
  title,
  external,
  showExternalIcon,
  disabled,
  IconComponent,
  menuItemIconProps,
  modalContent,
}) => {
  const history = useHistory()
  const theme = useTheme()
  const [hovered, setHovered] = useState(false)
  const [showModal, setShowModal] = useState(false)

  return (
    <Drawer.Item>
      <MenuLinkStyles
        data-testid={`link-style-${title}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
        disabled={disabled}
      >
        <Link
          to={to}
          external={external}
          disabled={disabled}
          modalContent={modalContent}
          exact
          activeStyle={{ backgroundColor: '#e3e3e3' }}
          onClick={(e) => {
            e.preventDefault()
            if (disabled) {
              e.stopPropagation()
              return
            }
            if (modalContent) {
              setTimeout(() => setShowModal(true), 250)
            } else {
              setTimeout(() => history.push(to), 250)
            }
          }}
        >
          <IconWrapper data-testid={title}>
            {IconComponent && (
              <IconComponent
                {...menuItemIconProps}
                color={hovered ? theme.menuLink.hoverColor : theme.menuLink.color}
              />
            )}
          </IconWrapper>
          <span>{title}</span>
          {(showExternalIcon ?? external) && (
            <Icon
              style={{ marginLeft: '10px' }}
              icon={faExternalLinkAlt}
              size="sm"
              color={hovered ? theme.menuLink.hoverColor : theme.menuLink.color}
            />
          )}
        </Link>
      </MenuLinkStyles>
      <Modal visible={showModal} onCloseClick={() => setShowModal(false)}>
        {modalContent}
      </Modal>
    </Drawer.Item>
  )
}

interface Divider {
  type: MenuItemType.Divider
}

export const divider: Divider = {
  type: MenuItemType.Divider,
}

interface Collapse {
  type: MenuItemType.Collapse
  items: DrawerMenuLinkProps[]
}

export const collapse = (items: DrawerMenuLinkProps[]): Collapse => ({
  type: MenuItemType.Collapse,
  items,
})

export type MenuItem = DrawerMenuLinkProps | Divider | Collapse
