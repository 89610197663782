import React from 'react'

import Icon, { IconProps } from './Icon'

const WarrantyIcon: React.FC<IconProps> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 90.19 95"
    path="M84.64,15.53,48.57.68a9.14,9.14,0,0,0-6.93,0L5.57,15.53A8.88,8.88,0,0,0,0,23.75c0,36.83,21.51,62.3,41.62,70.57a9.14,9.14,0,0,0,6.93,0c16.1-6.62,41.64-29.5,41.64-70.57A8.9,8.9,0,0,0,84.64,15.53ZM76,33.87,43.1,66.34a2.89,2.89,0,0,1-4,0L20.49,48a2.8,2.8,0,0,1,0-4l0,0,4-4a2.9,2.9,0,0,1,4,0L41.09,52.37l26.8-26.48a2.89,2.89,0,0,1,4,0l4,4a2.82,2.82,0,0,1,0,4l0,0Z"
  />
)

export default WarrantyIcon
