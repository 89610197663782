export type Auth0Config = {
  domain: string
  realm: string
  clientId: string
  audience: string
  managementAudience: string
  registerUrl?: string
  callbackUrl: string
  logoutUrl: string
  scope: string
  pwResetEndpoint: string
  pwResetAPIKey: string
}

export type APIConfig = {
  baseUrl: string
  apiVersion: string
}

export type GTMConfig = {
  gtmId: string
  auth: string
  preview: 'env-1' | 'env-3'
}

type DomainObject = {
  [key: string]: {
    auth0: Auth0Config
    API: APIConfig
    GTM: GTMConfig
    GA_ID: string
    [key: string]: any
  }
}

type DomainConfig = {
  development: DomainObject
  test: DomainObject
  qa?: DomainObject
  production: DomainObject
}

type Brand = 'whitelabel' | 'verlinde' | 'rm' | 'demag' | 'swf' | 'donati'
type Env = 'dev' | 'qa' | 'pilot' | 'prod'
type Auth0RawConfig = {
  [key in Brand]: {
    [env in Env]: {
      domain: string
      clientId: string
      audience: string
      managementAudience: string // Auth0 Management API
    }
  }
}

type PasswordResetConfig = {
  [env in Env]: {
    endpoint: string
    apikey: string
  }
}

type RegistrationUrlConfig = {
  [brand in Brand]: string | undefined
}

// add configured Auth0 tenants here
const auth0RawConfig: Auth0RawConfig = {
  whitelabel: {
    dev: {
      domain: 'swf-dev-cidm.eu.auth0.com',
      clientId: 'ea1YfyU1VmvTuLxbuR1toM7GRDXK6K1r',
      audience: 'https://api.dev.swf.io',
      managementAudience: 'https://swf-dev-cidm.eu.auth0.com/api/v2/',
    },
    qa: {
      domain: 'swf-dev.eu.auth0.com',
      clientId: 'ekgLGfoWqBWMbfTyy8QMZkJ5V5hQWQ1W',
      audience: 'https://api.dev.swf.io',
      managementAudience: 'https://swf-dev.eu.auth0.com/api/v2/',
    },
    pilot: {
      domain: 'swf-dev.eu.auth0.com',
      clientId: 'ekgLGfoWqBWMbfTyy8QMZkJ5V5hQWQ1W',
      audience: 'https://api.dev.swf.io',
      managementAudience: 'https://swf-dev.eu.auth0.com/api/v2/',
    },
    prod: {
      domain: 'swf-dev.eu.auth0.com',
      clientId: 'ekgLGfoWqBWMbfTyy8QMZkJ5V5hQWQ1W',
      audience: 'https://api.dev.swf.io',
      managementAudience: 'https://swf-dev.eu.auth0.com/api/v2/',
    },
  },
  swf: {
    dev: {
      domain: 'login.dev.swfkrantechnik.io',
      clientId: 'ea1YfyU1VmvTuLxbuR1toM7GRDXK6K1r',
      audience: 'https://api.dev.swf.io',
      managementAudience: 'https://swf-dev-cidm.eu.auth0.com/api/v2/',
    },
    qa: {
      domain: 'login.qa.swfkrantechnik.io',
      clientId: '5zAPBj3kb1HaY2KQdQ4ASh9aexu1P7e2',
      audience: 'https://api.qa.swf.io',
      managementAudience: 'https://swf-qa-cidm.eu.auth0.com/api/v2/',
    },
    pilot: {
      domain: 'login.swfkrantechnik.com',
      clientId: 'NZOkJy5TFcvtUKHEt6hY4hPlcorWEZht',
      audience: 'https://api.prod.swf.io',
      managementAudience: 'https://swf-prod-cidm.eu.auth0.com/api/v2/',
    },
    prod: {
      domain: 'login.swfkrantechnik.com',
      clientId: 'NZOkJy5TFcvtUKHEt6hY4hPlcorWEZht',
      audience: 'https://api.prod.swf.io',
      managementAudience: 'https://swf-prod-cidm.eu.auth0.com/api/v2/',
    },
  },
  verlinde: {
    dev: {
      domain: 'login.dev.verlinde.io',
      clientId: 'XOub20YCffoC74mUIrhdK044nWDo1TMz',
      audience: 'https://api.dev.verlinde.io',
      managementAudience: 'https://verlinde-dev-cidm.eu.auth0.com/api/v2/',
    },
    qa: {
      domain: 'login.qa.verlinde.io',
      clientId: 'WVEHMfUCdQQ1F0r59Cg2ApBJIPWj1OSG',
      audience: 'https://api.qa.verlinde.io',
      managementAudience: 'https://verlinde-qa-cidm.eu.auth0.com/api/v2/',
    },
    pilot: {
      domain: 'login.verlinde.com',
      clientId: 'cKsM7cjvswVsOmp9WBRe160L17rOO5ze',
      audience: 'https://api.prod.verlinde.io',
      managementAudience: 'https://verlinde-prod-cidm.eu.auth0.com/api/v2/',
    },
    prod: {
      domain: 'login.verlinde.com',
      clientId: 'cKsM7cjvswVsOmp9WBRe160L17rOO5ze',
      audience: 'https://api.prod.verlinde.io',
      managementAudience: 'https://verlinde-prod-cidm.eu.auth0.com/api/v2/',
    },
  },
  rm: {
    dev: {
      domain: 'login.dev.rmhoist.io',
      clientId: 'Ppps0aLFAh3oiDP0FLfoR0cSNH9HtQEQ',
      audience: 'https://api.dev.rm.io',
      managementAudience: 'https://rm-dev-cidm.eu.auth0.com/api/v2/',
    },
    qa: {
      domain: 'login.qa.rmhoist.io',
      clientId: 'YX5eskdgBGCjCkWyuNfcbC95xM0B4QS8',
      audience: 'https://api.qa.rm.io',
      managementAudience: 'https://rm-qa-cidm.eu.auth0.com/api/v2/',
    },
    pilot: {
      domain: 'login.rmhoist.com',
      clientId: '8ZVJD7oIaKR2xUGZdl3OBsPPxGgJ93Km',
      audience: 'https://api.prod.rm.io',
      managementAudience: 'https://rm-prod-cidm.eu.auth0.com/api/v2/',
    },
    prod: {
      domain: 'login.rmhoist.com',
      clientId: '8ZVJD7oIaKR2xUGZdl3OBsPPxGgJ93Km',
      audience: 'https://api.prod.rm.io',
      managementAudience: 'https://rm-prod-cidm.eu.auth0.com/api/v2/',
    },
  },
  demag: {
    dev: {
      domain: 'login.dev.demagcranes.io',
      clientId: 'WKdQFhx2sk4hCDCoKkK0hFdHqPkq0Zkx',
      audience: 'https://api.dev.demag.io',
      managementAudience: 'https://demag-dev-cidm.eu.auth0.com/api/v2/',
    },
    qa: {
      domain: 'login.qa.demagcranes.io',
      clientId: 'pc95lZldyKFHnrxYNVOLv4TmcH3xVuKx',
      audience: 'https://api.qa.demag.io',
      managementAudience: 'https://demag-qa-cidm.eu.auth0.com/api/v2/',
    },
    pilot: {
      domain: 'login.demagcranes.com',
      clientId: 'nyRWIoFAHT04uvdbIESpp2MAnOyGSS2y',
      audience: 'https://api.prod.demag.io',
      managementAudience: 'https://demag-prod-cidm.eu.auth0.com/api/v2/',
    },
    prod: {
      domain: 'login.demagcranes.com',
      clientId: 'nyRWIoFAHT04uvdbIESpp2MAnOyGSS2y',
      audience: 'https://api.prod.demag.io',
      managementAudience: 'https://demag-prod-cidm.eu.auth0.com/api/v2/',
    },
  },
  donati: {
    dev: {
      domain: 'login-dev.donaticranes.com',
      clientId: 'h8Lu6kI5oK0qlDpyXf7Hys6rVTp0Fa3p',
      audience: 'https://api.dev.donati.io',
      managementAudience: 'https://donati-dev-cidm.eu.auth0.com/api/v2/',
    },
    qa: {
      domain: 'login-qa.donaticranes.com',
      clientId: 'vWx96JN3luYXpDt9peWuBpg8EI3ZYZ4O',
      audience: 'https://api.qa.donati.io',
      managementAudience: 'https://donati-qa-cidm.eu.auth0.com/api/v2/',
    },
    pilot: {
      domain: 'login-pilot.donaticranes.com',
      clientId: 'vnE7Rzbg156BQsKm4fQ2zMUbHcTiQ2Cs',
      audience: 'https://api.pilot.donati.io',
      managementAudience: 'https://donati-pilot-cidm.eu.auth0.com/api/v2/',
    },
    prod: {
      domain: 'login.donaticranes.com',
      clientId: 'vnE7Rzbg156BQsKm4fQ2zMUbHcTiQ2Cs',
      audience: 'https://api.prod.donati.io',
      managementAudience: 'https://donati-prod-cidm.eu.auth0.com/api/v2/',
    },
  },
}

const passwordResetConfig: PasswordResetConfig = {
  dev: {
    endpoint: 'https://apigateway-sit.konecranes.com/ws/rest/cidm/password/reset',
    apikey: '41e24abc-b692-4976-8ac3-c706cc1945bc',
  },
  qa: {
    endpoint: 'https://apigateway-qa.konecranes.com/ws/rest/cidm/password/reset',
    apikey: '97aae77c-123f-40aa-971b-28a51315d09f',
  },
  pilot: {
    endpoint: 'https://apigateway.konecranes.com/ws/rest/cidm/password/reset',
    apikey: '34065273-a195-4f43-9317-4c398e11204b',
  },
  prod: {
    endpoint: 'https://apigateway.konecranes.com/ws/rest/cidm/password/reset',
    apikey: '34065273-a195-4f43-9317-4c398e11204b',
  },
}

const registrationUrlConfig: RegistrationUrlConfig = {
  swf: 'https://mysupport.swfkrantechnik.com/swf/en/register',
  verlinde: 'https://store.verlinde.com/ver/en/register',
  rm: 'https://distributorportal.rmhoist.com/rmi/en/register',
  demag: undefined,
  donati: undefined,
  whitelabel: undefined,
}

const getAuth0Config = (callbackDomain: string, brand: Brand, env: Env): Auth0Config => {
  return {
    domain: auth0RawConfig[brand][env].domain,
    realm: 'db',
    clientId: auth0RawConfig[brand][env].clientId,
    audience: auth0RawConfig[brand][env].audience,
    registerUrl: registrationUrlConfig[brand],
    callbackUrl: callbackDomain,
    logoutUrl: callbackDomain,
    managementAudience: auth0RawConfig[brand][env].managementAudience,
    scope: 'openid email profile acp',
    pwResetEndpoint: passwordResetConfig[env].endpoint,
    pwResetAPIKey: passwordResetConfig[env].apikey,
  }
}

const domainConfig: DomainConfig = {
  development: {
    localhost: {
      auth0: getAuth0Config('http://localhost:3000', 'whitelabel', 'dev'),
      API: {
        baseUrl: 'http://localhost:3001/local',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'sOCQJoRiIaSn2m488LY5ow',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'swf.local': {
      auth0: getAuth0Config('http://swf.local:3000', 'swf', 'dev'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-MM8WGCW',
        auth: 'rIeT9oNtNJfarM4CM01Lhg',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'rmhoist.local': {
      auth0: getAuth0Config('http://rmhoist.local:3000', 'rm', 'dev'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-N4STMRQ',
        auth: 'XAJg5Y7mYRKwm8n3DCnOEw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
  },
  production: {
    'dev.swfkrantechnik.io': {
      auth0: getAuth0Config('https://dev.swfkrantechnik.io', 'swf', 'dev'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-MM8WGCW',
        auth: 'rIeT9oNtNJfarM4CM01Lhg',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'dev.rmhoist.io': {
      auth0: getAuth0Config('https://dev.rmhoist.io', 'rm', 'dev'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-N4STMRQ',
        auth: 'XAJg5Y7mYRKwm8n3DCnOEw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'dev.verlinde.io': {
      auth0: getAuth0Config('https://dev.verlinde.io', 'verlinde', 'dev'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-NKQNXBP',
        auth: 'QmdKGBD7lFQb2ZQmp3y0Aw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'ui.dev.alpha.konecranes.io': {
      auth0: getAuth0Config('https://ui.dev.alpha.konecranes.io', 'whitelabel', 'dev'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'sOCQJoRiIaSn2m488LY5ow',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'dev.donaticranes.io': {
      auth0: getAuth0Config('https://dev.donaticranes.io', 'donati', 'dev'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-TT83BQJ',
        auth: 'NwjorE_Tm6vvte3Eu9-6Zw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'dev.demagcranes.io': {
      auth0: getAuth0Config('https://dev.demagcranes.io', 'demag', 'dev'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'sOCQJoRiIaSn2m488LY5ow',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'ui.qa.alpha.konecranes.io': {
      auth0: getAuth0Config('https://ui.qa.alpha.konecranes.io', 'whitelabel', 'qa'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'sOCQJoRiIaSn2m488LY5ow',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'qa.swfkrantechnik.io': {
      auth0: getAuth0Config('https://qa.swfkrantechnik.io', 'swf', 'qa'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-MM8WGCW',
        auth: 'rIeT9oNtNJfarM4CM01Lhg',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'qa.verlinde.io': {
      auth0: getAuth0Config('https://qa.verlinde.io', 'verlinde', 'qa'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-NKQNXBP',
        auth: 'QmdKGBD7lFQb2ZQmp3y0Aw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'qa.demagcranes.io': {
      auth0: getAuth0Config('https://qa.demagcranes.io', 'demag', 'qa'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'sOCQJoRiIaSn2m488LY5ow',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'qa.donaticranes.io': {
      auth0: getAuth0Config('https://qa.donaticranes.io', 'donati', 'qa'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-TT83BQJ',
        auth: 'NwjorE_Tm6vvte3Eu9-6Zw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'cleverportal-qa.donaticranes.com': {
      auth0: getAuth0Config('https://cleverportal-qa.donaticranes.com', 'donati', 'qa'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-TT83BQJ',
        auth: 'NwjorE_Tm6vvte3Eu9-6Zw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'qa.rmhoist.io': {
      auth0: getAuth0Config('https://qa.rmhoist.io', 'rm', 'qa'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-N4STMRQ',
        auth: 'XAJg5Y7mYRKwm8n3DCnOEw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
    'ui.pilot.alpha.konecranes.io': {
      auth0: getAuth0Config('https://ui.pilot.alpha.konecranes.io', 'whitelabel', 'pilot'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'sOCQJoRiIaSn2m488LY5ow',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-11',
    },
    'pilot.swfkrantechnik.io': {
      auth0: getAuth0Config('https://pilot.swfkrantechnik.io', 'swf', 'pilot'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-MM8WGCW',
        auth: 'rIeT9oNtNJfarM4CM01Lhg',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-11',
    },
    'pilot.verlinde.io': {
      auth0: getAuth0Config('https://pilot.verlinde.io', 'verlinde', 'pilot'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-NKQNXBP',
        auth: 'QmdKGBD7lFQb2ZQmp3y0Aw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-11',
    },
    'pilot.demagcranes.io': {
      auth0: getAuth0Config('https://pilot.demagcranes.io', 'demag', 'pilot'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'sOCQJoRiIaSn2m488LY5ow',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-11',
    },
    'pilot.donaticranes.io': {
      auth0: getAuth0Config('https://pilot.donaticranes.io', 'donati', 'pilot'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-TT83BQJ',
        auth: 'NwjorE_Tm6vvte3Eu9-6Zw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-11',
    },
    'cleverportal-pilot.donaticranes.com': {
      auth0: getAuth0Config('https://cleverportal-pilot.donaticranes.com', 'donati', 'pilot'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-TT83BQJ',
        auth: 'NwjorE_Tm6vvte3Eu9-6Zw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-11',
    },
    'pilot.rmhoist.io': {
      auth0: getAuth0Config('https://pilot.rmhoist.io', 'rm', 'pilot'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-N4STMRQ',
        auth: 'XAJg5Y7mYRKwm8n3DCnOEw',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-11',
    },
    'ui.prod.alpha.konecranes.io': {
      auth0: getAuth0Config('https://ui.prod.alpha.konecranes.io', 'whitelabel', 'prod'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'HA4q9x4Sft2a04nktO0paQ',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'ui.prod.swfkrantechnik.io': {
      auth0: getAuth0Config('https://ui.prod.swfkrantechnik.io', 'swf', 'prod'),
      API: {
        baseUrl: 'https://api.prod.swfkrantechnik.io',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-MM8WGCW',
        auth: 'h7yX6zvxGSO0io1aItTJAw',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'swfmysupport.swfkrantechnik.com': {
      auth0: getAuth0Config('https://swfmysupport.swfkrantechnik.com', 'swf', 'prod'),
      API: {
        baseUrl: 'https://api.swfmysupport.swfkrantechnik.com',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-MM8WGCW',
        auth: 'h7yX6zvxGSO0io1aItTJAw',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'ui.prod.verlinde.io': {
      auth0: getAuth0Config('https://ui.prod.verlinde.io', 'verlinde', 'prod'),
      API: {
        baseUrl: 'https://api.prod.verlinde.io',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-NKQNXBP',
        auth: 'RD-pDQLcnibcUoR0qo-NDA',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'my.verlinde.com': {
      auth0: getAuth0Config('https://my.verlinde.com', 'verlinde', 'prod'),
      API: {
        baseUrl: 'https://api.my.verlinde.com',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-NKQNXBP',
        auth: 'RD-pDQLcnibcUoR0qo-NDA',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'prod.demagcranes.io': {
      auth0: getAuth0Config('https://prod.demagcranes.io', 'demag', 'prod'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'juaEjnAgbbcVHoMsfio4fg',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'prod.donaticranes.io': {
      auth0: getAuth0Config('https://prod.donaticranes.io', 'donati', 'prod'),
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-TT83BQJ',
        auth: 'wWqDGSwHbodZlXETaXmuuQ',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'cleverportal.donaticranes.com': {
      auth0: getAuth0Config('https://cleverportal.donaticranes.com', 'donati', 'prod'),
      API: {
        baseUrl: 'https://api.cleverportal.donaticranes.com',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-TT83BQJ',
        auth: 'wWqDGSwHbodZlXETaXmuuQ',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'ui.prod.rmhoist.io': {
      auth0: getAuth0Config('https://ui.prod.rmhoist.io', 'rm', 'prod'),
      API: {
        baseUrl: 'https://api.prod.rmhoist.io',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-N4STMRQ',
        auth: 'Gvomm56OGgCI0QCi2kwCRA',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'rmdistributorportal.rmhoist.com': {
      auth0: getAuth0Config('https://rmdistributorportal.rmhoist.com', 'rm', 'prod'),
      API: {
        baseUrl: 'https://api.rmdistributorportal.rmhoist.com',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-N4STMRQ',
        auth: 'Gvomm56OGgCI0QCi2kwCRA',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'ui.prod.demagcranes.io': {
      auth0: getAuth0Config('https://ui.prod.demagcranes.io', 'demag', 'prod'),
      API: {
        baseUrl: 'https://api.prod.demagcranes.io',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'juaEjnAgbbcVHoMsfio4fg',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'demagcranes.live': {
      auth0: getAuth0Config('https://demagcranes.live', 'demag', 'prod'),
      API: {
        baseUrl: 'https://api.demagcranes.live',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'juaEjnAgbbcVHoMsfio4fg',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
    'www.demagcranes.live': {
      auth0: getAuth0Config('https://demagcranes.live', 'demag', 'prod'),
      API: {
        baseUrl: 'https://api.demagcranes.live',
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'juaEjnAgbbcVHoMsfio4fg',
        preview: 'env-1',
      },
      GA_ID: 'UA-51661459-11',
    },
  },
  test: {
    localhost: {
      auth0: {
        domain: 'dummydomain.dev',
        realm: 'dummy-db',
        clientId: 'dummy-client-id',
        audience: 'https://api.dev.swf.io',
        managementAudience: 'https://swf-dev.eu.auth0.com/api/v2/',
        callbackUrl: 'http://localhost:3000',
        logoutUrl: 'http://localhost:3000',
        scope: 'openid email profile acp',
        pwResetAPIKey: '123123123',
        pwResetEndpoint: 'https://test.cidm.io',
      },
      API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        apiVersion: process.env.REACT_APP_API_VERSION,
      },
      GTM: {
        gtmId: 'GTM-T8KG3QW',
        auth: 'sOCQJoRiIaSn2m488LY5ow',
        preview: 'env-3',
      },
      GA_ID: 'UA-51661459-10',
    },
  },
}

export default domainConfig
