import styled from '@emotion/styled'

interface WidgetStylesProps {
  color?: string
  iconColor?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  shadow?: boolean
  titleHoverColor?: string
}

export const WidgetStyles = styled.div<WidgetStylesProps>`
  box-sizing: border-box;
  & * {
    box-sizing: inherit;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || '#f7f7f7'};
  box-shadow: ${({ shadow }) => shadow && '0px 3px 2px #00000029'};
  border: none;
  text-decoration: none;
  color: ${({ color }) => color || '#5B5B5B'};
  padding: 10px 10px 0 10px;
  transition: 0.3s background-color, 0.3s box-shadow ease-out, 0.3s color;
  overflow: hidden;

  p {
    margin: 0;
  }

  svg {
    color: ${(props) => props.iconColor || props.color || '#707070'};
  }

  &:hover {
    // Feels better with hover color disabled
    // background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor || '#e8e8e8'};
    box-shadow: ${({ shadow }) => shadow && '0px 4px 6px #00000029'};
    p {
      color: ${({ titleHoverColor }) => titleHoverColor};
      transition: 0.2s color;
    }
  }

  a {
    color: inherit;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`

export const Title = styled.span<{ color?: string }>`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${({ color }) => color};
  margin-left: 15px;
`

export const NewsFeedContainer = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  font-size: 0.9rem;
  background-color: #ffffff;
  color: ${({ color }) => color};
  height: 135px;
`

export const NewsFeedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #cccccc;
  height: 45px;
  overflow: hidden;

  &:hover {
    opacity: 0.7;
  }
`

export const ShowMore = styled.div<{ color?: string }>`
  padding: 0 10px;
  font-size: 0.8rem;
  text-align: right;
  color: ${({ color }) => color};
  height: 30px;
  line-height: 30px;

  a:hover {
    opacity: 0.7;
  }
`

export const PaddedBox = styled.div`
  padding: 10px;
`
