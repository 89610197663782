import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'

import FEATURES from '../constants/features'
import { Module } from '../themes'
import useFeatureDisabled from './useFeatureDisabled'
import useAccessToken from './useAccessToken'

const isWarrantyDisabled = (accessesClaim?: { [key: string]: { [key: string]: string[] } }) => {
  if (!accessesClaim) return true

  const fioriKeyCidmKey = Object.keys(accessesClaim).find((key) => key.endsWith('SAP_FIORI'))
  const fioriCidm = fioriKeyCidmKey && accessesClaim[fioriKeyCidmKey]
  const fioriUser = fioriCidm && fioriCidm.SAP_FIORI_USER

  return fioriUser?.includes('SAP_FIORI_EXT_CUST_NOTIF') ? false : true
}

export default function useModules() {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const isFeatureDisabled = useFeatureDisabled()
  const {
    status: accessTokenStatus,
    result: { getClaim },
  } = useAccessToken()

  const applyCustomModuleConfig = (m: Module): Module => {
    let newModule = {
      ...m,
    }

    if (m.name === 'eLearning' && m.disabled) {
      newModule.modalContent = (
        <>
          <h3>{t('coming_soon')}</h3>
        </>
      )
    }

    if (m.name === 'myPartnersAndEquipment' && m.disabled) {
      // TODO: remove this when Asset fleet is launched to revert back to normal
      // Artem: my guess is that it should use FEATURES.CUSTOMERS as a permission check here.
      // Now uses FEATURES.ASSET_FLEET.
      newModule.modalContent = (
        <>
          <h3>{t('coming_soon')}</h3>
        </>
      )
    }

    if (m.name === 'myPartnersAndEquipment' && isFeatureDisabled(FEATURES.CUSTOMERS, ['R'])) {
      newModule.title = t('my_equipment')
      newModule.tileTitle = t('my_equipment')
      newModule.pageTitle = t('my_equipment')
      newModule.menuItemTitle = t('my_equipment')
    }

    if (m.name === 'training' && theme.brand === 'swf' && i18n.language === 'de') {
      // This feature is currently specific to SWF.
      newModule.to = 'https://www.swfkrantechnik.com/de/service/schulungen'
    }

    // Don't do any permission checks when it's a demo module (production).
    if (m.name === 'warranty' && !m.demo) {
      if (accessTokenStatus !== 'success') {
        newModule.disabled = true
      } else {
        const accessesClaim = getClaim('accesses')
        newModule.disabled = isWarrantyDisabled(accessesClaim)
      }
    }

    return newModule
  }

  const modules = theme.modules.map((m) => {
    // Modify props based on the context.
    const hydratedModule: Module = {
      ...m,
      title: t(m.title),
      tileTitle: t(m.tileTitle || m.title),
      pageTitle: t(m.pageTitle || m.title),
      menuItemTitle: t(m.menuItemTitle || m.title),
      description: m.description && t(m.description),
      tooltip: m.tooltip && t(m.tooltip),
      disabled:
        m.disabled ?? (m.permissionCheckArgs && isFeatureDisabled(...m.permissionCheckArgs!)),
    }

    return applyCustomModuleConfig(hydratedModule)
  })

  return modules
}
