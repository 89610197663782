import React from 'react'
import { useTranslation } from 'react-i18next'

import { Module } from '../../themes'
import useModules from '../../hooks/useModules'
import { GridContainer } from '../../components/DashboardTiles/DashboardTiles.styles'
import HeaderText from '../../styles/HeaderText'
import GridTile from '../GridTile'
import { ModalContentContainer, ModalContentGrid } from './NewEquipmentMenu.styles'

// SWF only.
const NewEquipmentMenu = () => {
  const { t } = useTranslation()
  const modules = useModules()

  const storeModule = modules.find((m) => m.name === 'store')
  const chainSystemModule = modules.find((m) => m.name === 'chainSystem')
  const craneMasterModule = modules.find((m) => m.name === 'craneMaster')
  const menuModules = [storeModule!, chainSystemModule!, craneMasterModule!]

  // istanbul ignore next
  if (!menuModules.every(Boolean)) throw new Error('Missing module config in NewEquipmentMenu')

  // FIXME: copied from DashbaordTiles, needs to be factored out and reused
  const renderGridItem = (m: Module, moduleIdx: number) => {
    const {
      name,
      to,
      tileTitle,
      description,
      secondary,
      disabled,
      external,
      showExternalIcon,
      demo,
      unlocked,
      hideDescription,
      modalContent,
      tileProps,
      IconComponent,
      tileIconProps,
    } = m

    const {
      color,
      backgroundColor,
      hoverBackgroundColor,
      descriptionColor,
      descriptionBackgroundColor,
      demoBackgroundColor,
      iconColor,
    } = tileProps || {}

    const icon = IconComponent && <IconComponent {...tileIconProps} />
    const msGridRow = moduleIdx * 2 + 1 // Account for 1-based index and gaps.

    // TODO: type
    const props = {
      key: moduleIdx,
      name,
      to,
      title: tileTitle!,
      description: !hideDescription ? description : '',
      secondary,
      disabled,
      external,
      showExternalIcon,
      demo,
      unlocked,
      modalContent,
      color,
      backgroundColor,
      hoverBackgroundColor,
      descriptionColor,
      descriptionBackgroundColor,
      demoBackgroundColor,
      iconColor,
      icon,
      msGridRow,
    }

    return <GridTile {...props} />
  }

  return (
    <ModalContentContainer>
      <GridContainer>
        <HeaderText style={{ margin: '0 0 1rem 0' }}>
          {t('new_equipment_configurators_and_spare_parts')}
        </HeaderText>
        <ModalContentGrid>{menuModules.map(renderGridItem)}</ModalContentGrid>
      </GridContainer>
    </ModalContentContainer>
  )
}

export default NewEquipmentMenu
