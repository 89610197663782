import React from 'react'
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'

import useDomain from '../../hooks/useDomain'

type AuthProviderProps = { children: React.ReactNode }

function AuthProvider({ children }: AuthProviderProps) {
  const domainSettings = useDomain()

  const { domain, clientId, callbackUrl, audience, scope } = domainSettings.auth0
  const authConfig: Auth0ProviderOptions = {
    domain,
    clientId,
    audience,
    scope,
    redirectUri: callbackUrl,
    cacheLocation: 'localstorage',
    onRedirectCallback: (appState) => {
      window.location.href = appState.target
    },
    // useRefreshTokens: true, // IIFT4-935
    // Use the below audience and scope to log in and get user consent on localhost.
    // audience: domainSettings.auth0.managementAudience,
    // scope: 'read:current_user update:current_user_metadata',
  }

  return <Auth0Provider {...authConfig}>{children}</Auth0Provider>
}

export { AuthProvider }
