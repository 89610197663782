/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react'
import React, { useMemo, Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ROUTE_ITEMS, { RouteType } from './constants/routes'
import useModules from './hooks/useModules'
import usePermission from './hooks/usePermission'
import BreadcrumbsNav from './components/BreadcrumbsNav'
import Loader from './components/Loader'

const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'))
const NotAllowedPage = React.lazy(() => import('./pages/NotAllowedPage'))
const RedirectPage = React.lazy(() => import('./pages/RedirectPage'))

const Routes: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const theme = useTheme()
  const modules = useModules()
  const permissions = usePermission()

  const routes = useMemo<RouteType[]>(
    (): RouteType[] =>
      ROUTE_ITEMS.map((route) => {
        if (route.path === '/' && theme.brand === 'demag') {
          const storeModule = modules.find((m) => m.name === 'store')

          if (!storeModule) throw new Error('Store module not found for Demag.')

          return {
            ...route,
            component: undefined,
            render: (props) => (
              <RedirectPage
                redirectName={storeModule.title}
                redirectUrl={storeModule.to}
                {...props}
              />
            ),
          }
        }

        const module = modules.find((m) => m.name === route.name)

        if (!module || module.disabled || module.external) {
          return {
            ...route,
            component: module?.disabled ? NotAllowedPage : NotFoundPage,
          }
        }

        return {
          ...route,
          title: module.title,
        }
      }),
    [modules, theme.brand],
  )

  const breadcrumbRoutes = useMemo(
    () => routes.map(({ path, title }) => ({ path, title })),
    [routes],
  )

  return (
    <main
      css={css`
        flex: auto;
        min-height: 500px;
      `}
    >
      <BreadcrumbsNav routes={breadcrumbRoutes} hiddenRoutes={['/']} />
      {permissions.loading && <Loader loadingText={t('loading')} />}
      {permissions.result && (
        <Suspense fallback={null}>
          <Switch location={location}>
            {routes.map((r) => (
              <Route key={r.path} {...r} />
            ))}
            <Route key="page-not-found" component={NotFoundPage} />
          </Switch>
        </Suspense>
      )}
    </main>
  )
}

export default Routes
