import React from 'react'

import Icon, { IconProps } from './Icon'

const NewsIcon: React.FC<IconProps> = (props) => (
  <Icon
    {...props}
    path="M493,101.09H84.08c-26,0-47.18,20.55-47.18,45.87V422.14C36.9,447.46,58,468,84.08,468H493c26,0,47.18-20.54,47.18-45.86V147C540.15,121.64,519,101.09,493,101.09ZM269.13,384.39a10,10,0,0,1-10,10h-141a10,10,0,0,1-10-10v-16a10,10,0,0,1,10-10h141a10,10,0,0,1,10,10Zm0-63a10,10,0,0,1-10,10h-141a10,10,0,0,1-10-10v-16a10,10,0,0,1,10-10h141a10,10,0,0,1,10,10Zm0-63a10,10,0,0,1-10,10h-141a10,10,0,0,1-10-10v-16a10,10,0,0,1,10-10h141a10,10,0,0,1,10,10Zm199,126a10,10,0,0,1-10,10h-142a10,10,0,0,1-10-10v-142a10,10,0,0,1,10-10h142a10,10,0,0,1,10,10Zm0-201.64a10,10,0,0,1-10,10h-340a10,10,0,0,1-10-10V169.2a10,10,0,0,1,10-10h340a10,10,0,0,1,10,10Z"
    viewBox="36.9 101.09 503.28 366.91"
  />
)

export default NewsIcon
