import React from 'react'

type BreadcrumbState = {
  crumbs: React.ReactNode | null
  setCrumbs: React.Dispatch<React.SetStateAction<React.ReactNode | null>>
}

const BreadcrumbContext = React.createContext<BreadcrumbState | undefined>(undefined)

export const BreadcrumbProvider: React.FC = ({ children }) => {
  const [crumbs, setCrumbs] = React.useState<React.ReactNode | null>(null)
  const value = { crumbs, setCrumbs }

  return <BreadcrumbContext.Provider value={value}>{children}</BreadcrumbContext.Provider>
}

export default BreadcrumbContext
