import styled from '@emotion/styled'

import { mq } from '../../constants/breakpoints'

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  align-items: center;

  ${mq.mobile} {
    padding: 0;
  }

  ${mq.xs_mobile} {
    padding: 0;
  }
`

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  position: relative;

  @media (max-height: 593px) {
    position: absolute;
    top: 60px;
    min-width: auto;
    min-height: auto;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`
