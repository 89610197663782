import styled from '@emotion/styled'

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  /* remove focus outline borders */
  a,
  input,
  button,
  svg {
    outline: none;
  }

  /* change the default autofill boxshadow */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
`
