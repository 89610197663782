import React, { useState, useEffect } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { DashboardTile } from '@kc/alphabrands-component-library'
import { useTheme } from '@emotion/react'

import ExternalLink from '../ExternalLink'
import Modal from '../Modal'
import NewsWidget from '../NewsWidget'
import { GridItem } from './GridTile.styles'
import { loadMavenoid } from '../../vendor/mavenoid'
import useKeys from '../../hooks/useKeys'
import { BrandName } from '../../themes'

type LinkProps = RouterLinkProps & { to: string; external?: boolean }

const Link = ({ external, children, to, ...props }: LinkProps) => {
  if (external) {
    return <ExternalLink to={to}>{children}</ExternalLink>
  }

  return (
    <RouterLink to={to} {...props}>
      {children}
    </RouterLink>
  )
}

// Also makes sense to pick types from the module?
interface GridTileProps {
  // Module props:
  name: string
  to: string
  title: string
  description?: string
  tooltip?: string
  disabled?: boolean
  external?: boolean
  showExternalIcon?: boolean
  demo?: boolean
  unlocked?: boolean
  modalContent?: JSX.Element
  // Tile props:
  secondary?: boolean
  color?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  descriptionColor?: string
  descriptionBackgroundColor?: string
  demoBackgroundColor?: string
  shadow?: boolean
  // Rendered with tile icon props:
  icon?: JSX.Element
  // IE11 grid props:
  msGridRow?: number
  msGridColumn?: number
  msGridRowSpan?: number
}

export const openMavenoid = (mavenoidKey: string | undefined, brand: BrandName) => {
  if (mavenoidKey) {
    try {
      loadMavenoid({ apiKey: mavenoidKey, brand })
    } catch (error) {
      console.error(error)
    }
  }
}

const GridTile: React.FC<GridTileProps> = ({
  name,
  to,
  tooltip,
  disabled,
  external,
  showExternalIcon,
  unlocked,
  secondary,
  modalContent,
  msGridRow,
  msGridColumn,
  msGridRowSpan,
  ...tileProps
}) => {
  const { t } = useTranslation()
  const KeyContext = useKeys()
  const { brand } = useTheme()
  const [showModal, setShowModal] = useState(false)
  const direction = secondary ? 'row' : 'column'
  const hasTooltip = !!tooltip

  useEffect(() => {
    if (hasTooltip) {
      // Needed to rebind new tooltips.
      // If tooltips are ever used outside of this component,
      // better use MutationObserver to rebuild all tooltips.
      ReactTooltip.rebuild()
    }
  }, [hasTooltip])

  return (
    <GridItem
      gridRow={!secondary ? 'span 2' : 'span 1'}
      msGridRow={msGridRow}
      msGridColumn={msGridColumn}
      msGridRowSpan={msGridRowSpan}
      disabled={disabled}
      data-tip={tooltip} // Let's add it here for now.
    >
      <Modal visible={showModal} onCloseClick={() => setShowModal(false)}>
        {modalContent || (
          <>
            <h3>{t('request_access')}</h3>
            <p>{t('request_access_description')}</p>
          </>
        )}
      </Modal>

      {name === 'news' ? (
        <NewsWidget
          title={tileProps.title}
          to={to}
          icon={tileProps.icon}
          color={tileProps.color}
          backgroundColor={tileProps.backgroundColor}
          hoverBackgroundColor={tileProps.hoverBackgroundColor}
          shadow={tileProps.shadow}
        />
      ) : disabled || modalContent || name === 'troubleshooting' ? (
        <DashboardTile
          {...tileProps}
          unlocked={unlocked}
          disabled={disabled}
          external={showExternalIcon ?? external}
          direction={direction}
          onClick={() => {name === 'troubleshooting' ? openMavenoid(KeyContext.result?.keys['mavenoid-key'], brand) : setShowModal(true)}}
          data-testid={disabled ? 'request-access-link' : 'open-modal-content-link'}
        />
      ) : (
        <Link to={to} external={external}>
          <DashboardTile
            {...tileProps}
            direction={direction}
            disabled={disabled}
            unlocked={unlocked}
            external={showExternalIcon ?? external}
          />
        </Link>
      )}
    </GridItem>
  )
}

export default GridTile
