import styled from '@emotion/styled'

import { mq } from '../../constants/breakpoints'

const getMsGridRows = (tileCount: number, columnCount: number) => {
  const rowCount = Math.ceil(tileCount / columnCount)
  let gridRows = '100px' // First row
  for (let i = 0; i < rowCount - 1; i++) {
    gridRows += ' 20px 100px' // Add other rows
  }
  return gridRows
}

export const ModalContentContainer = styled.div`
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
  max-width: 90vw;
  margin: 0 auto;
  min-width: 450px;

  ${mq.xs_mobile} {
    height: 100%;
    min-width: auto;
  }

  ${mq.mobile} {
    height: 100%;
  }

  ${mq.tablet} {
    height: 100%;
  }
`

export const ModalContentGrid = styled.div<{
  tileCount: number
}>`
  display: grid;
  display: -ms-grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 100px;

  -ms-grid-columns: 1fr 20px 1fr;
  -ms-grid-rows: ${({ tileCount }) => getMsGridRows(tileCount, 2)};

  ${mq.mobile} {
    grid-template-columns: repeat(1, 1fr);
    -ms-grid-columns: 1fr;
    -ms-grid-rows: ${({ tileCount }) => getMsGridRows(tileCount, 1)};
  }

  ${mq.xs_mobile} {
    grid-template-columns: repeat(1, 1fr);
    -ms-grid-columns: 1fr;
    -ms-grid-rows: ${({ tileCount }) => getMsGridRows(tileCount, 1)};
  }
`
