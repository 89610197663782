export function loadMavenoid(props) {
  const brandMapping = {
    swf: 'swf',
    donati: 'donati',
    verlinde: 'verlinde',
    demag: 'demag',
    rm: 'r&m',
    whiteLabel: 'swf',
    localhost: 'swf',
  }

  window['mavenoidOrg'] = brandMapping[props.brand]

  const mavenoidLoader = (m, a, v, e, n, o, i, d) => {

    d = m.createElement(a);
    d.async = true;
    d.src = 'https://widget-hosts.mavenoid.com/custom-embedding-scripts/' + n + '.js';
    i = m.getElementsByTagName(a)[0];
    i.parentNode.insertBefore(d, i);
    v[e] = v[e] || [];

    d.onload = () => {
      // eslint-disable-next-line no-undef
      openMavenoid()
    }
  }

  if (props.apiKey === null || props.brand === null || !brandMapping[props.brand]) {
    console.error('No matching brand found', props.brand)
    return
  }

  mavenoidLoader(document, 'script', window, 'mavenoid', 'crane')
}
