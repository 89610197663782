import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import { LoaderStyles } from './Loader.styles'

const Loader: React.FC<{ loadingText: string }> = ({ loadingText }) => {
  return (
    <LoaderStyles data-testid="loader">
      <Icon icon={faSyncAlt} size="3x" />
      <p>{loadingText}</p>
    </LoaderStyles>
  )
}

export default Loader
