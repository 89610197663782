import React, { useEffect } from 'react'
import { ThemeProvider as BaseThemeProvider, Global, css } from '@emotion/react'

import themes, { Theme, ThemeName } from '../../themes'
import { getBrandName } from '../../themes/helpers'
import DonatiStyles from '../../themes/styles/Donati.styles'
import DemagStyles from '../../themes/styles/Demag.styles'

const GlobalStyles: React.FC<{ theme: Theme }> = ({ theme }) => {
  return (
    <>
      <Global
        styles={css`
          body {
            font-family: ${theme.fontFamily}, sans-serif, Arial;
          }
        `}
      />
      {theme.brand === 'donati' && <DonatiStyles />}
      {theme.brand === 'demag' && <DemagStyles />}
    </>
  )
}

export const ThemeProvider: React.FC<{ theme?: Theme; brand?: ThemeName }> = ({
  children,
  brand = getBrandName(),
  theme = themes[brand].themeConfig,
}) => {
  useEffect(() => {
    themes[brand].loadTheme()
  }, [brand])

  return (
    <BaseThemeProvider theme={theme}>
      <GlobalStyles theme={theme} />
      {children}
    </BaseThemeProvider>
  )
}
