let loaded = false
let identified = false

function splitString(input, maxLength = 200) {
  const result = [];

  for (let i = 0; i < input.length; i += maxLength) {
    const substring = input.slice(i, i + maxLength);
    result.push(substring);
  }

  return result;
}

export function loadHotjar(token, encryptEmailAddress) {
  if (process.env.NODE_ENV !== 'production') return

  if (loaded) {
    console.error('Hotjar can only be initialized once.')
    if(!identified && token&& token.status === 'success' && token.result.decoded && localStorage.getItem('cookiesAccepted') === 'true') {
      ;(async function (h) {
        const userAttributes = {}
        for (var key in token.result.decoded.payload) {
          if(key.includes('email')){
            const encryptedEmail = await encryptEmailAddress(token.result.decoded.payload[key])
            const strings =  splitString(encryptedEmail)
            for (let i = 0; i < strings.length; i++) {
              userAttributes['encryptedEmail' + i] = strings[i];
            }
          }
          if(key.includes('customerId')){
            userAttributes['customerId'] = token.result.decoded.payload[key]
          }
          if(key.includes('cidm_role')){
            userAttributes['role'] = token.result.decoded.payload[key]
          }
          if(key.includes('accountno')){
            userAttributes['account'] = token.result.decoded.payload[key]
          }
          if(key.includes('countrycode')){
            userAttributes['country'] = token.result.decoded.payload[key]
          }
        }
        h.hj('identify', null, userAttributes)
      })(window)

      identified = true
    }
    return
  }

  ;(function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: 1645733, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')

  loaded = true

}


