import styled from '@emotion/styled'

export const GridItem = styled.div<{
  gridColumn?: string
  gridRow?: string
  order?: number
  msGridRow?: number
  msGridColumn?: number
  msGridRowSpan?: number
  disabled?: boolean // HTML disabled
}>`
  grid-column: ${(props) => props.gridColumn || 'auto'};
  grid-row: ${(props) => props.gridRow || 'auto'};
  order: ${(props) => props.order || 'initial'};

  -ms-grid-row: ${(props) => props.msGridRow || 1};
  -ms-grid-column: ${(props) => props.msGridColumn || 1};
  -ms-grid-row-span: ${(props) => props.msGridRowSpan || 1};

  a {
    text-decoration: none;
  }
`
