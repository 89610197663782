import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { HeaderStyles, StylelessButton } from './PopupMobileHeader.styles'

interface PopupMobileHeaderProps {
  headerText: string
  onClose: () => void
}

const PopupMobileHeader: React.FC<PopupMobileHeaderProps> = ({ headerText, onClose }) => (
  <HeaderStyles>
    <p>{headerText}</p>
    <StylelessButton onClick={onClose}>
      <Icon icon={faTimes} color="white" size="lg" />
    </StylelessButton>
  </HeaderStyles>
)

export default PopupMobileHeader
