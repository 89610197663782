import { useContext } from 'react'

import KeyContext from '../context/KeyContext'

export default function useKeys() {
  const context = useContext(KeyContext)

  // istanbul ignore next
  if (context === undefined) {
    throw new Error('useKeys must be used within KeyProvider')
  }

  return context
}
