import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Popup } from '@kc/alphabrands-component-library'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'

import { DropdownButton, DropdownContent, Name, MenuItem, Divider } from './AccountDropdown.styles'
import PopupMobileHeader from '../PopupMobileHeader'
import useScreenSize from '../../hooks/useScreenSize'
import internalDomains from '../../constants/internalDomains'

export interface AccountDropdownProps {
  dealerName: string
  alignment?: 'left' | 'right'
  onLogout?: () => void
  onChangePassword?: () => void
  name: string
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({
  onLogout,
  onChangePassword,
  alignment = 'right',
  name,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth0()
  const { isTablet, isDesktop } = useScreenSize()
  const isSmallDevices = !isTablet && !isDesktop

  const isInternalUser = (email: string): boolean => {
    const domain = email && email.indexOf('@') ? email.split('@')[1] : undefined
    return !domain || internalDomains.includes(domain)
  }

  return (
    <>
      <Popup
        nodeId="account-popup"
        alignment={alignment}
        positionStyles={isSmallDevices ? { position: 'fixed', top: 0, left: 0, right: 0 } : null}
        relativePosition={{ top: 20, left: 19 }}
      >
        <Popup.Trigger>
          <DropdownButton aria-label="Account Dropdown">
            <>
              <Icon icon={faUser} />
              <Name>{name}</Name>
            </>
          </DropdownButton>
        </Popup.Trigger>
        <Popup.Content>
          {({ closeDropdown }) => (
            <>
              {isSmallDevices && (
                <PopupMobileHeader headerText={t('profile')} onClose={closeDropdown} />
              )}
              <DropdownContent data-testid="dropdown-content">
                <MenuItem>{user!.email}</MenuItem>
                {!isInternalUser(user!.email!) && (
                  <MenuItem onClick={onChangePassword}>{t('change_password')}</MenuItem>
                )}
                <Divider />
                <MenuItem onClick={onLogout}>{t('logout')}</MenuItem>
              </DropdownContent>
            </>
          )}
        </Popup.Content>
      </Popup>
    </>
  )
}

AccountDropdown.propTypes = {
  alignment: PropTypes.oneOf(['left', 'right']),
  dealerName: PropTypes.string.isRequired,
}

export default AccountDropdown
