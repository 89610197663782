import React from 'react'

type Target = '_self' | '_blank'

const ExternalLink: React.FC<{
  children?: JSX.Element | JSX.Element[] | React.ReactNode | React.ReactNode[]
  to?: string
  target?: Target
}> = ({ children, to = '', target = '_blank' }) => {
  return (
    <a href={to} target={target} rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default ExternalLink
