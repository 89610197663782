import React from 'react'
import { useAsync, UseAsyncReturn } from 'react-async-hook'
import { useAuth0 } from '@auth0/auth0-react'

import { Keys } from '../../api/types'
import useAPIClient from '../../hooks/useAPIClient'

export type KeyState = UseAsyncReturn<Keys>

const KeyContext = React.createContext<KeyState | undefined>(undefined)

export function KeyProvider({ children }: { children: React.ReactNode }) {
  const apiClient = useAPIClient()
  const { isAuthenticated } = useAuth0()

  const value = useAsync<Keys>(async () => {
    if (isAuthenticated) {
      const { data } = await apiClient.getKeys()
      return data
    } else {
      // istanbul ignore next
      return {
        keys: {},
      }
    }
  }, [isAuthenticated])

  return <KeyContext.Provider value={value}>{children}</KeyContext.Provider>
}

export default KeyContext
