import { useContext } from 'react'

import DomainContext from '../context/DomainContext'

export default function useDomain() {
  const context = useContext(DomainContext)

  // istanbul ignore next
  if (context === undefined) {
    throw new Error('useDomain must be used within DomainProvider')
  }

  return context
}
