import React from 'react'

import Icon, { IconProps } from './Icon'

const TrackerIcon: React.FC<IconProps> = (props) => (
  <Icon
    {...props}
    path="M42,0A42,42,0,0,0,0,42c0,18.76,27.46,53,38,65.36a5.25,5.25,0,0,0,8.07,0C56.57,95,84,60.77,84,42A42,42,0,0,0,42,0Zm.18,70.81c-16.77,0-30.36-12.43-30.36-27.76S25.42,15.3,42.19,15.3,72.55,27.72,72.55,43.05,59,70.81,42.19,70.81ZM24.84,49.91V35.65L40,41.77V57.48Zm19.21,7.57V41.76l15.13-6.11V49.91Zm-19-25.32L42,25.76l16.93,6.69L42,39.14Z"
    viewBox="0 0 84.03 109.26"
  />
)

export default TrackerIcon
