import React from 'react'
import { useAsync, UseAsyncReturn } from 'react-async-hook'
import { useAuth0 } from '@auth0/auth0-react'

import { Permissions } from '../../api/types'
import useAPIClient from '../../hooks/useAPIClient'

export type PermissionState = UseAsyncReturn<Permissions>

const PermissionContext = React.createContext<PermissionState | undefined>(undefined)

export function PermissionProvider({ children }: { children: React.ReactNode }) {
  const apiClient = useAPIClient()
  const { isAuthenticated } = useAuth0()

  const value = useAsync<Permissions>(async () => {
    if (isAuthenticated) {
      const { data } = await apiClient.getPermissions()
      return data
    } else {
      // FIXME: figure out a better way of dealing with async hooks that wait for authentication.
      // istanbul ignore next
      return {
        schemaVersion: 1,
        gcmid: '',
        realm: '',
        role: '',
        features: [],
      }
    }
  }, [isAuthenticated])

  return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>
}

export default PermissionContext
