const internalDomains = [
  'craneproparts.com',
  'dbis.biz',
  'demag.com.au',
  'demagcranes.com',
  'donaticranes.com',
  'drivecon.com',
  'konecranes.com',
  'ksr-kran.de',
  'mhe-demag.com',
  'nosturiexpertit.fi',
  'morriscranessystems.com',
  'rmhoist.com',
  'swfkrantechnik.com',
  'tba.group',
  'verlinde.com',
]

export default internalDomains
