import React from 'react'

import Icon, { IconProps } from './Icon'

const ChainSystemIcon: React.FC<IconProps> = (props) => (
  <Icon
    {...props}
    path="M45,37.86a12.46,12.46,0,0,1-4.24,9.62,15.6,15.6,0,0,1-10.71,4.33c-4.46,0-9.84-1.54-12.2-7.77a13.48,13.48,0,0,1-.17-7.25c.59-1.38,1.06-2,3.46,1,.92,1.15,4.07,4.39,4.07,4.39a6.37,6.37,0,0,0,9,0,6.43,6.43,0,0,0,0-9l-5.47-5.72-1.7-1.65a6.19,6.19,0,0,1-.93-3.64l.1-5a14.6,14.6,0,0,0,3.46.39,14.28,14.28,0,0,0,3.26-.35c0,.94,0,3.83.12,4.18a5.74,5.74,0,0,0,1.8,2.21,79.7,79.7,0,0,1,6.31,5A13.85,13.85,0,0,1,45,37.86ZM29.56,16.15c9.21,0,9.73-6.87,9.73-6.87H19.83S20.35,16.15,29.56,16.15Zm-9.12,14v1.28a.64.64,0,0,1-.64.64H6.39v.64a.64.64,0,0,1-.64.63H4.47a.64.64,0,0,1-.64-.63v-.64H.64A.64.64,0,0,1,0,31.45V30.17a.64.64,0,0,1,.64-.64H3.83v-.64a.64.64,0,0,1,.64-.63H5.75a.64.64,0,0,1,.64.63v.64H19.8A.64.64,0,0,1,20.44,30.17Zm0-6.38v1.27a.64.64,0,0,1-.64.64H16.6v.64A.64.64,0,0,1,16,27H14.69a.64.64,0,0,1-.64-.64V25.7H.64A.64.64,0,0,1,0,25.06V23.79a.64.64,0,0,1,.64-.64H14.05v-.64a.64.64,0,0,1,.64-.64H16a.64.64,0,0,1,.63.64v.64h3.2A.64.64,0,0,1,20.44,23.79Zm0-6.39v1.28a.64.64,0,0,1-.64.64H11.5V20a.64.64,0,0,1-.64.64H9.58A.64.64,0,0,1,8.94,20v-.63H.64A.64.64,0,0,1,0,18.68V17.4a.64.64,0,0,1,.64-.64h8.3v-.64a.64.64,0,0,1,.64-.64h1.28a.64.64,0,0,1,.64.64v.64h8.3A.64.64,0,0,1,20.44,17.4ZM19.77,8H39.23V0H19.77Z"
    viewBox="0 0 45 51.81"
  />
)

export default ChainSystemIcon
