import styled from '@emotion/styled'

import { mq } from '../../constants/breakpoints'

export const LanguageSelectorButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  color: white;
  font-size: 1rem;
  cursor: pointer;

  svg:first-of-type {
    margin-right: 12px;

    ${mq.xs_mobile} {
      margin-right: 6px;
    }
  }
`

export const LanguageList = styled.ul`
  border-radius: 3px;
  list-style: none;
  background-color: white;
  padding: 10px 0;
  border: 1px solid black;
  margin: 0;

  ${mq.mobile} {
    padding: 0;
    border: none;
    border-bottom: 1px solid #707070;
  }
`

export const Language = styled.li<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 15px;
  background-color: ${({ active }) => (active ? '#e2e2e2' : 'initial')};

  &:hover {
    background-color: lightgrey;
  }

  ${mq.mobile} {
    padding: 14px 25px;
    color: #707070;
    font-size: 1.2rem;

    &:not(:last-child) {
      border-bottom: 1px solid #707070;
    }
  }
`
