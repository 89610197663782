import React from 'react'

import Icon, { IconProps } from './Icon'

const TrainingIcon: React.FC<IconProps> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 115.67 92.53"
    path="M112.77,75.18H69A5.69,5.69,0,0,1,63,81h-11a6.13,6.13,0,0,1-5.92-5.79H2.89A2.9,2.9,0,0,0,0,78.07V81A11.59,11.59,0,0,0,11.57,92.53H104.1A11.6,11.6,0,0,0,115.67,81v-2.9A2.91,2.91,0,0,0,112.77,75.18ZM104.1,8.67A8.7,8.7,0,0,0,95.42,0H20.24a8.69,8.69,0,0,0-8.67,8.67V69.4H104.1ZM92.53,57.83H23.13V11.57h69.4ZM57.83,19.69A14.34,14.34,0,1,0,72.17,34,14.34,14.34,0,0,0,57.83,19.69Zm6.69,15.73L54.35,41.26A1.4,1.4,0,0,1,52.28,40V28a1.4,1.4,0,0,1,2.07-1.22L64.52,33A1.39,1.39,0,0,1,64.52,35.42Z"
  />
)

export default TrainingIcon
