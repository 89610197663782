import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { ModalContainer, ModalContent, CloseButton } from './Modal.styles'

// TODO: we need proper dialogs with focus and scroll lock.
// + make sure we don't show multiple at a time, which is an anti-pattern in UX.
const Modal: React.FC<{ visible?: boolean; onCloseClick?: () => void; className?: string }> = ({
  children,
  visible,
  onCloseClick,
  className,
}) => {
  const [delayed, setDelayed] = useState(false)
  const modalRoot = document.getElementById('modal-root')
  const modalRootNotFound = !modalRoot

  // In some cases the target portal node may not exist until the first full render.
  useEffect(() => {
    if (!modalRootNotFound) return

    if (!delayed) {
      setDelayed(true)
    } else {
      // Error out if ultimately not found.
      throw new Error('Modal root element not found')
    }
  }, [delayed, modalRootNotFound])

  // Strictly checking for false because `visible` is optional.
  if (modalRootNotFound || visible === false) return null

  const modal = ReactDOM.createPortal(
    <ModalContainer className={className} onClick={onCloseClick}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onCloseClick} data-testid="modal-close-icon">
          <Icon icon={faTimes} size="2x" />
        </CloseButton>
        {children}
      </ModalContent>
    </ModalContainer>,
    modalRoot!,
  )

  return modal
}

export default Modal
