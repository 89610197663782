import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { useTheme } from '@emotion/react'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { languages } from '../../utils/i18n'
import useDomain from '../../hooks/useDomain'
import useScreenSize from '../../hooks/useScreenSize'
import useModules from '../../hooks/useModules'
import useAccessToken from '../../hooks/useAccessToken'
import DrawerMenu from '../../components/DrawerMenu'
import AccountDropdown from '../AccountDropdown'
import LanguageSelector from '../LanguageSelector'
import Modal from '../Modal'
import Loader from '../Loader'
import ExternalLink from '../ExternalLink'
import {
  HeaderStyles,
  TopHeader,
  BottomHeader,
  TopRightPart,
  HeaderIconPlaceholder,
  BrandImage,
  SiteTitle,
  MainMenu,
  ContactLink,
  BannerTextContainer,
  PasswordModalContent,
  FlexFiller,
} from './Header.styles'

export interface HeaderProps {
  headerText: string
}

const brandMap: { [key: string]: string } = {
  SWF_Global: 'SWF',
  Verlinde_Global: 'VER',
  'R&M_Global': 'RM',
  Demag_Global: 'DEM',
}

const Header: React.FC<HeaderProps> = ({ headerText }) => {
  const { user, logout } = useAuth0()
  const { i18n, t } = useTranslation()
  const { isTablet, isDesktop, isMobile } = useScreenSize()
  const { brand, headerLogo, altHeaderLogo, headerLogoSize, altHeaderLogoSize, dashboardBanner } =
    useTheme()
  const location = useLocation()
  const modules = useModules()
  const {
    auth0: { logoutUrl, pwResetEndpoint, pwResetAPIKey },
  } = useDomain()
  const {
    currentPromise: accessTokenPromise,
    result: { getClaim },
  } = useAccessToken()

  const [showPwReset, setShowPwReset] = useState(false)
  const [pwResetMessage, setPwResetMessage] = useState('')

  const resetPassword = async () => {
    setShowPwReset(true)
    setPwResetMessage('')

    // Make sure it's fulfilled before we continue.
    await accessTokenPromise
    const brandClaim = getClaim('brand')

    if (brand) {
      try {
        await axios.post(
          pwResetEndpoint,
          { username: user!.email, source: brandMap[brandClaim] },
          { headers: { 'x-api-key': pwResetAPIKey } },
        )

        setPwResetMessage(t('password_change_successful'))
      } catch (error) {
        console.error('[resetPassword]', error)
        setPwResetMessage(t('password_change_failed'))
      }
    }
  }

  const contactModule = modules.find((m) => m.name === 'contact')
  const isSmallDevice = !isTablet && !isDesktop
  const isFrontPage = brand !== 'whiteLabel' && location.pathname === '/'
  const borderColor = isFrontPage ? dashboardBanner?.borderColor : undefined
  const bannerImage = isMobile
    ? dashboardBanner?.mobileImage
    : isTablet
    ? dashboardBanner?.tabletImage
    : dashboardBanner?.desktopImage

  const isBannerTextCentered = isFrontPage && ['swf', 'rm'].includes(brand)
  const logo = isFrontPage ? headerLogo : altHeaderLogo || headerLogo
  const logoWidth = isFrontPage ? headerLogoSize : altHeaderLogoSize || headerLogoSize

  if (!contactModule) throw new Error('Missing contact module config in Header')

  const LinkComponent = ({ children }: { children: JSX.Element }) =>
    contactModule.external ? (
      <ExternalLink to={contactModule.to}>{children}</ExternalLink>
    ) : (
      <Link to={contactModule.to}>{children}</Link>
    )

  return (
    <HeaderStyles>
      <Modal visible={showPwReset} onCloseClick={() => setShowPwReset(false)}>
        <h1>{t('change_password')}</h1>
        <PasswordModalContent>
          {pwResetMessage === '' ? (
            <Loader loadingText={t('loading_please_wait')}></Loader>
          ) : (
            pwResetMessage
          )}
        </PasswordModalContent>
      </Modal>
      <TopHeader data-testid="topHeaderContainer">
        <MainMenu>
          <DrawerMenu />
        </MainMenu>
        <SiteTitle>{headerText}</SiteTitle>
        <TopRightPart>
          {brand !== 'demag' && (
            <ContactLink>
              <LinkComponent>
                <>
                  <Icon icon={faEnvelope} />{' '}
                  {!isSmallDevice && <span>{contactModule.menuItemTitle!}</span>}
                </>
              </LinkComponent>
            </ContactLink>
          )}
          <LanguageSelector
            languages={languages}
            activeLanguage={i18n.language}
            onLanguageChange={i18n.changeLanguage}
          />
          <AccountDropdown
            dealerName="Dealer"
            name={isSmallDevice ? '' : `${user?.given_name} ${user?.family_name}`}
            onLogout={() => logout({ returnTo: logoutUrl })}
            onChangePassword={() => resetPassword()}
          />
        </TopRightPart>
      </TopHeader>
      <BottomHeader
        backgroundImage={bannerImage}
        borderColor={borderColor}
        largeHeader={isFrontPage}
        hasText={!!dashboardBanner?.title}
        className={`bottom-header__${brand}`}
      >
        {logo ? (
          <Link to="/">
            <BrandImage src={logo} alt={brand} style={{ width: logoWidth }} />
          </Link>
        ) : (
          <HeaderIconPlaceholder />
        )}
        {isFrontPage && dashboardBanner?.title && (
          <BannerTextContainer>
            <div>
              <h1>{t(dashboardBanner?.title)}</h1>
              {!isMobile && dashboardBanner.subtitle && <span>{t(dashboardBanner.subtitle)}</span>}
            </div>
          </BannerTextContainer>
        )}
        {isBannerTextCentered && <FlexFiller />}
      </BottomHeader>
    </HeaderStyles>
  )
}

Header.propTypes = {
  headerText: PropTypes.string.isRequired,
}

export default Header
