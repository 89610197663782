import { useContext } from 'react'

import BreadcrumbContext from '../context/BreadcrumbContext'

export default function useBreadcrumbs() {
  const context = useContext(BreadcrumbContext)

  // istanbul ignore next
  if (context === undefined) {
    throw new Error('useBreadcrumbs must be used within BreadcrumbProvider')
  }

  return context
}
