import React from 'react'
import { useAsync, UseAsyncReturn } from 'react-async-hook'
import { useAuth0 } from '@auth0/auth0-react'

import { CustomerId } from '../../api/types'
import useAPIClient from '../../hooks/useAPIClient'

export type CustomerIdState = UseAsyncReturn<CustomerId | undefined>

const CustomerIdContext = React.createContext<CustomerIdState | undefined>(undefined)

export function CustomerIdProvider({ children }: { children: React.ReactNode }) {
  const apiClient = useAPIClient()
  const { isAuthenticated } = useAuth0()

  const value = useAsync<CustomerId | undefined>(async () => {
    // istanbul ignore next
    if (!isAuthenticated) return

    const { data } = await apiClient.getCustomerId()
    return data
  }, [isAuthenticated])

  return <CustomerIdContext.Provider value={value}>{children}</CustomerIdContext.Provider>
}

export default CustomerIdContext
