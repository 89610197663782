import React, { useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import APIClient from '../../api/APIClient'
import useDomain from '../../hooks/useDomain'

type APIClientProviderProps = { children: React.ReactNode; mockClient?: APIClient }

const APIClientContext = React.createContext<APIClient | undefined>(undefined)

// Could actually remove the whole mock logic from here and use
// <APIClientContext.Provider /> direclty in the test wrapper.
export function APIClientProvider({ children, mockClient }: APIClientProviderProps) {
  const domain = useDomain()
  const { getAccessTokenSilently } = useAuth0()

  // mockClient for tests.
  const apiClient = useMemo(
    () =>
      mockClient ??
      new APIClient({
        ...domain.API,
        getAccessTokenSilently,
      }),
    [domain.API, getAccessTokenSilently, mockClient],
  )

  return <APIClientContext.Provider value={apiClient}>{children}</APIClientContext.Provider>
}

export default APIClientContext
