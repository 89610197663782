import { Feature } from '../api/types'
import { getFeature, hasPermission } from '../utils/permission-helpers'
import usePermission from './usePermission'
import useAccessToken from './useAccessToken'

// Feature names in ACP vs CIDM.
const featureNameMap: { [key: string]: string } = {
  BusinessDashboard: 'ACP_DASHBOARD',
  Troubleshooting: 'ACP_TROUBLESHOOTING',
}

export default function useFeatureDisabled() {
  const permissions = usePermission()
  const {
    status: accessTokenStatus,
    result: { getClaim },
  } = useAccessToken()

  let explicitPermissions: string[] = []

  if (accessTokenStatus === 'success') {
    const cidmRole = getClaim('cidm_role')
    const accessesClaim = getClaim('accesses')
    const cidmKey =
      accessesClaim && Object.keys(accessesClaim).find((key) => key.endsWith('_ALPHAPORTAL'))
    const cidmEntry = cidmKey && accessesClaim[cidmKey]
    explicitPermissions = (cidmEntry && cidmEntry[cidmRole]) || []
  }

  const isFeatureDisabled = (
    featureName: Feature['name'],
    requiredPermission: Feature['grants'],
  ) => {
    if (!permissions.result?.features) return true

    const feature = getFeature(permissions.result?.features, featureName)
    const featurePermissions = feature?.grants || []

    // Allowed on the role level -- don't check any further.
    if (hasPermission(featurePermissions, requiredPermission)) return false

    if (requiredPermission.includes('R')) {
      // User-level permissions only grant read access.
      return !explicitPermissions.includes(featureNameMap[featureName])
    }

    return true
  }

  return isFeatureDisabled
}
