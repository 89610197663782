import React, { useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionHeaderContent,
  Chevron,
} from './Accordion.styles'

const Accordion: React.FC<{
  headerContent: JSX.Element
  children: JSX.Element | JSX.Element[]
  open?: boolean
}> = ({ headerContent, open = false, children }) => {
  const [isOpen, setIsOpen] = useState(open)

  return (
    <AccordionContainer>
      <AccordionHeader onClick={() => setIsOpen(!isOpen)}>
        <AccordionHeaderContent>{headerContent}</AccordionHeaderContent>
        <Chevron>
          <Icon icon={isOpen ? faChevronUp : faChevronDown} size="1x" />
        </Chevron>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionContainer>
  )
}

export default Accordion
