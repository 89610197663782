import { Theme } from './index'
import whitelabelLogo from './logos/whitelabel.svg'
import whiteWhitelabelLogo from './logos/whitelabel-white.svg'
import healthAppImage from './images/swf-health-app.png'
import qrAppStore from './images/swf-appstore-qr.png'
import qrGooglePlay from './images/swf-googleplay-qr.png'
import { getBrandedModule } from './defaultModules'

const themeConfig: Theme = {
  brand: 'whiteLabel',
  privacyPolicyLink: 'https://www.konecranes.com/data-protection',
  cookiePolicyLink: 'https://www.konecranes.com/data-protection-cookie-statement',
  companyLink: 'https://www.konecranes.com',
  siteTermsLink: '/terms-and-conditions/alpha-portal/latest.default.html',
  fontFamily: 'Arial',
  headerLogo: whitelabelLogo,
  menuLogo: whitelabelLogo,
  footerLogo: whiteWhitelabelLogo,
  headerLogoSize: '80px',
  menuLogoSize: '80px',
  footerLogoSize: '60px',
  linkColor: '#0076FF',
  linkHoverColor: '#0076FF',
  header: {},
  healthApp: {
    qrAppStore,
    qrGooglePlay,
    image: healthAppImage,
    appStoreUrl: 'https://apps.apple.com/app/x-link/id1494895162',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.swf.xlink',
    moreInfoUrl: 'https://www.swfkrantechnik.com/en/new-app-swf-x-link',
  },
  button: {
    primary: {
      textColor: 'white',
      color: '#0076FF',
      hover: '#0076FF',
    },
    color: '#0076FF',
  },
  menuLink: {
    color: '#4B5457',
    hoverColor: 'white',
    hoverBackgroundColor: '#0076FF',
  },
  listItem: {},
  modules: [
    // Main modules below:
    getBrandedModule('dashboard'),
    getBrandedModule('contact'),
    getBrandedModule('myPartnersAndEquipment', {
      hideDescription: true,
    }),
    getBrandedModule('eLearning', {
      secondary: true,
    }),
    getBrandedModule('monitoredAsset'),
    getBrandedModule('troubleshooting', {
      secondary: true,
    }),
    getBrandedModule('businessDashboard', {
      secondary: true,
    }),
    getBrandedModule('healthApp', {
      secondary: true,
    }),
    getBrandedModule('hotjarDecrypt'),
    getBrandedModule('termsOfSale'),
    getBrandedModule('store', {
      secondary: true,
      external: true,
    }),
    getBrandedModule('chainSystem', {
      secondary: true,
    }),
    getBrandedModule('generalPriceList', {
      secondary: true,
      external: true,
    }),
    getBrandedModule('stagemakerPriceList', {
      secondary: true,
      external: true,
    }),
    getBrandedModule('deliveryTracker', {
      secondary: true,
      external: true,
    }),
    getBrandedModule('warranty', {
      secondary: true,
    }),
    getBrandedModule('assetFleet', {
      secondary: true,
    }),
    getBrandedModule('activationFlow'),
    // Demo modules below:
    getBrandedModule('trainingCalendar', {
      demo: true,
    }),
    getBrandedModule('marketingBank', {
      demo: true,
    }),
    getBrandedModule('leadTimes', {
      demo: true,
    }),
    getBrandedModule('remoteDataDemo', {
      demo: true,
    }),
  ],
}

export function loadTheme(): void {
  loadIcon()
}

function loadIcon() {
  const head = document.getElementsByTagName('head')[0]
  const linkEl = document.createElement('link')

  linkEl.rel = 'icon'
  linkEl.type = 'image/x-icon'
  linkEl.href = './icons/whiteLabel.ico'
  head.insertBefore(linkEl, head.firstChild)
}

export default themeConfig
