import { useContext } from 'react'

import CustomerIdContext from '../context/CustomerIdContext'

export default function useCustomerId() {
  const context = useContext(CustomerIdContext)

  // istanbul ignore next
  if (context === undefined) {
    throw new Error('useCustomerId must be used within CustomerIdProvider')
  }

  return context
}
