import styled from '@emotion/styled'

export const HeaderStyles = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #707070;
  padding: 15px 25px;
  color: white;

  p {
    margin: 0;
  }
`

export const StylelessButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`
