import React from 'react'

import {
  dashboard,
  myPartnersAndEquipment,
  documentsAndManuals,
  trainingCalendar,
  leadTimes,
  leadTimeDemo,
  businessDashboard,
  marketingBank,
  chainSystem,
  craneMaster,
  warranty,
  troubleshooting,
  contact,
  eLearning,
  serviceDocuments,
  healthApp,
  assetFleet,
  remoteDataDemo,
  news,
  hotjarDecrypt,
  activationFlow,
  termsOfSale,
  monitoredAsset
} from '../themes/defaultModules'

const AssetsPage = React.lazy(() => import(/* webpackPrefetch: true */ '../pages/AssetsPage'))
const DashboardPage = React.lazy(() => import('../pages/DashboardPage'))
const AssetPage = React.lazy(() => import('../pages/AssetPage'))
const TrainingCalendarPage = React.lazy(() => import('../pages/TrainingCalendarPage'))
const RemoteDataDemoPage = React.lazy(() => import('../pages/RemoteDataDemoPage'))
const LeadTimesPage = React.lazy(() => import('../pages/LeadTimesPage'))
const LeadTimeDemoPage = React.lazy(() => import('../pages/LeadTimeDemoPage'))
const BusinessDashboardPage = React.lazy(() => import('../pages/BusinessDashboardPage'))
const DocumentsPage = React.lazy(() => import('../pages/DocumentsPage'))
const MarketingBankPage = React.lazy(() => import('../pages/MarketingBankPage'))
const WarrantyPage = React.lazy(() => import('../pages/WarrantyPage'))
const TroubleshootingPage = React.lazy(() => import('../pages/TroubleshootingPage'))
const ContactInfoPage = React.lazy(() => import('../pages/ContactInfoPage'))
const ElearningPage = React.lazy(() => import('../pages/ElearningPage'))
const ServiceDocumentsPage = React.lazy(() => import('../pages/ServiceDocumentsPage'))
const HealthAppPage = React.lazy(() => import('../pages/HealthAppPage'))
const TermsOfSalePage = React.lazy(() => import('../pages/TermsOfSalePage'))
const ChainSystemPage = React.lazy(() => import('../pages/ChainSystemPage'))
const CraneMasterPage = React.lazy(() => import('../pages/CraneMasterPage'))
const AssetFleetPage = React.lazy(() => import('../pages/AssetFleetPage'))
const MonitoredAssetPage = React.lazy(() => import('../pages/MonitoredAssetPage'))
const NewsPage = React.lazy(() => import('../pages/NewsPage'))
const HotjarPage = React.lazy(() => import('../pages/HotjarPage'))
const ActivationFlowPage = React.lazy(() => import('../pages/ActivationFlowPage'))

export type RouteType = {
  name: string
  path: string
  component?: React.ComponentType<any>
  render?: React.FC<any>
  exact?: boolean
  title?: string
}

const ROUTE_ITMES: RouteType[] = [
  {
    name: dashboard.name,
    path: dashboard.to,
    component: DashboardPage,
    exact: true,
  },
  {
    name: myPartnersAndEquipment.name,
    path: myPartnersAndEquipment.to,
    component: AssetsPage,
    exact: true,
  },
  {
    name: myPartnersAndEquipment.name,
    path: `${myPartnersAndEquipment.to}/:customerId`,
    component: AssetsPage,
    exact: true,
  },
  {
    name: myPartnersAndEquipment.name,
    path: `${myPartnersAndEquipment.to}/:customerId/order/:orderNo`,
    component: AssetPage,
    exact: true,
  },
  {
    name: documentsAndManuals.name,
    path: documentsAndManuals.to,
    component: DocumentsPage,
  },
  {
    name: trainingCalendar.name,
    path: trainingCalendar.to,
    component: TrainingCalendarPage,
  },
  {
    name: remoteDataDemo.name,
    path: remoteDataDemo.to,
    component: RemoteDataDemoPage,
  },
  {
    name: leadTimes.name,
    path: leadTimes.to,
    component: LeadTimesPage,
  },
  {
    name: leadTimeDemo.name,
    path: leadTimeDemo.to,
    component: LeadTimeDemoPage,
  },
  {
    name: businessDashboard.name,
    path: businessDashboard.to,
    component: BusinessDashboardPage,
  },
  {
    name: marketingBank.name,
    path: marketingBank.to,
    component: MarketingBankPage,
  },
  {
    name: chainSystem.name,
    path: chainSystem.to,
    component: ChainSystemPage,
  },
  {
    name: craneMaster.name,
    path: craneMaster.to,
    component: CraneMasterPage,
  },
  {
    name: warranty.name,
    path: warranty.to,
    component: WarrantyPage,
  },
  {
    name: troubleshooting.name,
    path: troubleshooting.to,
    component: TroubleshootingPage,
  },
  {
    name: contact.name,
    path: contact.to,
    component: ContactInfoPage,
  },
  {
    name: eLearning.name,
    path: eLearning.to,
    component: ElearningPage,
  },
  {
    name: serviceDocuments.name,
    path: serviceDocuments.to,
    component: ServiceDocumentsPage,
  },
  {
    name: healthApp.name,
    path: healthApp.to,
    component: HealthAppPage,
  },
  {
    name: termsOfSale.name,
    path: termsOfSale.to,
    component: TermsOfSalePage,
  },
  {
    name: assetFleet.name,
    path: assetFleet.to,
    component: AssetFleetPage,
  },
  {
    name: monitoredAsset.name,
    path: `${monitoredAsset.to}/:assetId`,
    component: MonitoredAssetPage,
  },
  {
    name: news.name,
    path: news.to,
    component: NewsPage,
  },
  {
    name: hotjarDecrypt.name,
    path: hotjarDecrypt.to,
    component: HotjarPage,
    exact: true,
  },
  {
    name: activationFlow.name,
    path: `${activationFlow.to}/:serialNumber`,
    component: ActivationFlowPage,
    exact: true,
  },
]

export default ROUTE_ITMES
