import React from 'react'
import { Global, css } from '@emotion/react'

const DonatiStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Frutiger';
        font-weight: 300;
        font-style: normal;
        src: local('Frutiger'), url('fonts/Frutiger-Light.woff') format('woff'),
          url('fonts/Frutiger-Light.woff2') format('woff2');
      }
      @font-face {
        font-family: 'Frutiger';
        font-weight: bold;
        font-style: normal;
        src: local('Frutiger'), url('fonts/Frutiger-Bold.woff') format('woff'),
          url('fonts/Frutiger-Bold.woff2') format('woff2');
      }

      * {
        font-weight: bold !important;
      }
    `}
  />
)

export default DonatiStyles
