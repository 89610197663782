import { useAuth0 } from '@auth0/auth0-react'
import { useAsync } from 'react-async-hook'
import IdTokenVerifier, { DecodedToken } from 'idtoken-verifier'

import useDomain from './useDomain'

// TODO: define known overloads
// const getClaim = (decodedAccessToken: undefined, claim: string): undefined
// const getClaim = (decodedAccessToken: DecodedToken, claim: 'brand'): 'SWF_Global' | 'Verlinde_Global' | 'R&M_Global' | 'Demag_Global'
// const getClaim = (decodedAccessToken: DecodedToken, claim: 'cidm_role'): 'ACP_ADMIN' | etc...
const getClaim = (decodedAccessToken: DecodedToken | undefined, claim: string): any => {
  if (!decodedAccessToken) return

  const { payload } = decodedAccessToken
  const claimKey = Object.keys(payload).find((key) => key.endsWith(`/${claim}`))

  return claimKey && payload[claimKey]
}

export default function useAccessToken() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const {
    auth0: { domain, clientId },
  } = useDomain()

  // Errors out when not logged in, so we just skip the token call.
  const asyncAccessToken = useAsync(async () => {
    // istanbul ignore next
    if (!isAuthenticated) return

    return getAccessTokenSilently()
  }, [isAuthenticated])

  const accessToken = asyncAccessToken.result
  let decodedAccessToken: DecodedToken | undefined

  if (accessToken) {
    // The example in the docs uses clientId as audience.
    // Has no affect in this case because we're merely decoding the token.
    const verifier = new IdTokenVerifier({
      audience: clientId,
      issuer: `https://${domain}/`,
    })

    decodedAccessToken = verifier.decode(accessToken)
  }

  // istanbul ignore next
  if (asyncAccessToken.status === 'error') {
    console.error('[useAccessToken]', asyncAccessToken.error)
  }

  return {
    ...asyncAccessToken,
    result: {
      encoded: accessToken,
      decoded: decodedAccessToken,
      getClaim: (claim: string) => getClaim(decodedAccessToken, claim),
    },
  }
}
