import queryString from 'query-string'

import { supportedThemes, ThemeName } from '.'

type BrandName = ThemeName

export const defaultTheme = 'whiteLabel'

// TODO: Some of these helpers should be moved to a more appropriate place.
// should also make it clear that getEnv !== NODE_ENV
export function getEnv() {
  const [, env] = process.env.REACT_APP_API_BASE_URL.split('.')
  return env
}

export function getUrlForEnv(baseUrl: string) {
  const env = getEnv()
  const [first, ...rest] = baseUrl.split('.')
  if (env === 'dev') return `${first}-dev.${rest.join('.')}`
  if (['qa', 'pilot'].includes(env)) return `${first}-qa.${rest.join('.')}`
  return baseUrl
}

export function getStoreUrls(eCommerceBaseUrl: string) {
  const url = getUrlForEnv(eCommerceBaseUrl)
  return {
    storeUrl: `${url}/login`,
    deliveryTrackerUrl: `${url}/my-account/orders`,
    documentsUrl: `${url}/documents`,
  }
}

export const domainThemeMap: { [key: string]: string } = {
  rmhoist: 'rm',
  swf: 'swfkrantechnik',
  demagcranes: 'demag',
  donaticranes: 'donati',
}

export function isSupportedTheme(domain: string): domain is ThemeName {
  if (supportedThemes.includes(domain)) {
    return true
  }

  return false
}

export function getDefaultTheme(): ThemeName {
  const { theme } = queryString.parse(window.location.search)
  const hostname = window.location.hostname
  const manualThemeIsAllowed =
    hostname === 'ui.dev.alpha.konecranes.io' ||
    hostname === 'localhost' ||
    hostname === 'alphaportal.local'

  if (typeof theme === 'string' && isSupportedTheme(theme) && manualThemeIsAllowed) {
    return theme
  }

  return defaultTheme
}

export function getBrandName(): BrandName {
  const domains = window.location.hostname.split('.')

  const brandName = domains.reduce((acc, domain) => {
    if (supportedThemes.includes(domain)) {
      acc = domain
    }

    if (supportedThemes.includes(domainThemeMap[domain])) {
      acc = domainThemeMap[domain]
    }

    return acc
  }, '')

  if (isSupportedTheme(brandName)) {
    return brandName
  }

  return getDefaultTheme()
}
