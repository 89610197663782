import { useTranslation } from 'react-i18next'

import { Module } from '../../themes'
import useModules from '../../hooks/useModules'
import { GridContainer } from '../../components/DashboardTiles/DashboardTiles.styles'
import HeaderText from '../../styles/HeaderText'
import GridTile from '../GridTile'
import { ModalContentContainer, ModalContentGrid } from './DocumentationMenu.styles'

// Donati only.
const DocumentationMenu = () => {
  const { t } = useTranslation()
  const modules = useModules()

  const electricChainHoists = modules.find((m) => m.name === 'electricChainHoists')
  const electricWireRopeHoists = modules.find((m) => m.name === 'electricWireRopeHoists')
  const jibCranes = modules.find((m) => m.name === 'jibCranes')
  const driveUnits = modules.find((m) => m.name === 'driveUnits')
  const endCarriages = modules.find((m) => m.name === 'endCarriages')
  const channelProfiles = modules.find((m) => m.name === 'channelProfiles')
  const craneSets = modules.find((m) => m.name === 'craneSets')
  const suspendedMonogirderBridgeCranes = modules.find(
    (m) => m.name === 'suspendedMonogirderBridgeCranes',
  )

  const menuModules = [
    electricChainHoists!,
    electricWireRopeHoists!,
    jibCranes!,
    driveUnits!,
    endCarriages!,
    channelProfiles!,
    craneSets!,
    suspendedMonogirderBridgeCranes!,
  ]

  // istanbul ignore next
  if (!menuModules.every(Boolean)) throw new Error('Missing module config in DocumentationMenu')

  // FIXME: copied from DashbaordTiles, needs to be factored out and reused
  const renderGridItem = (m: Module, moduleIdx: number) => {
    const {
      name,
      to,
      tileTitle,
      description,
      secondary,
      disabled,
      external,
      showExternalIcon,
      demo,
      unlocked,
      hideDescription,
      modalContent,
      tileProps,
      IconComponent,
      tileIconProps,
    } = m

    const {
      color,
      backgroundColor,
      hoverBackgroundColor,
      descriptionColor,
      descriptionBackgroundColor,
      demoBackgroundColor,
      iconColor,
    } = tileProps || {}

    const icon = IconComponent && <IconComponent {...tileIconProps} />
    const msGridRow = moduleIdx * 2 + 1 // Account for 1-based index and gaps.

    // TODO: type
    const props = {
      key: moduleIdx,
      name,
      to,
      title: tileTitle!,
      description: !hideDescription ? description : '',
      secondary,
      disabled,
      external,
      showExternalIcon,
      demo,
      unlocked,
      modalContent,
      color,
      backgroundColor,
      hoverBackgroundColor,
      descriptionColor,
      descriptionBackgroundColor,
      demoBackgroundColor,
      iconColor,
      icon,
      msGridRow,
    }

    return <GridTile {...props} />
  }

  return (
    <ModalContentContainer>
      <GridContainer>
        <HeaderText style={{ margin: '0 0 1rem 0' }}>{t('information_documents')}</HeaderText>
        <ModalContentGrid tileCount={menuModules.length}>
          {menuModules.map(renderGridItem)}
        </ModalContentGrid>
      </GridContainer>
    </ModalContentContainer>
  )
}

export default DocumentationMenu
