import styled from '@emotion/styled'

export const AccordionContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
`

export const AccordionHeader = styled.a`
  display: flex;
  flex: 1;
`

export const AccordionHeaderContent = styled.div`
  display: flex;
  flex: 1;
`

export const Chevron = styled.div`
  justify-self: flex-end;
`
