import React from 'react'
import { Global, css } from '@emotion/react'

const DemagStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Produkt Web';
        font-style: normal;
        src: local('Produkt Web'), url('/fonts/Produkt-Semibold-Web.woff') format('woff'),
          url('/fonts/Produkt-Semibold-Web.woff2') format('woff2');
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: Produkt Web, sans-serif, Arial;
      }

      p,
      footer,
      span,
      input {
        font-family: Istok Web, sans-serif, Arial;
      }

      .breadcrumbs-menu {
        p,
        span,
        a {
          font-family: Istok Web, sans-serif, Arial;
        }
      }

      .dashboard-grid {
        p {
          font-family: Produkt Web, sans-serif, Arial !important;
        }
      }

      .item-header {
        font-family: Produkt Web, sans-serif, Arial !important;
      }

      .drawer-menu {
        * {
          font-family: Produkt Web, sans-serif, Arial !important;
        }
      }
    `}
  />
)

export default DemagStyles
