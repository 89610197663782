import styled from '@emotion/styled'

import { mq } from '../../constants/breakpoints'

export const ModalContentContainer = styled.div`
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
  max-width: 90vw;
  margin: 0 auto;

  ${mq.xs_mobile} {
    height: 100%;
  }

  ${mq.mobile} {
    height: 100%;
  }

  ${mq.tablet} {
    height: 100%;
  }
`

export const ModalContentGrid = styled.div`
  display: grid;
  display: -ms-grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 100px;

  -ms-grid-columns: 1fr;
  -ms-grid-rows: 100px 20px 100px 20px 100px;
`
