import { faHome } from '@fortawesome/pro-solid-svg-icons'

import { Theme } from './index'
import { getEnv } from './helpers'
import demagLogo from './logos/demag.svg'
import whiteDemagLogo from './logos/demag-white.svg'
import { getBrandedModule } from './defaultModules'

const isProduction = getEnv() === 'prod'

const colors = {
  blue: '#0053A4',
  darkBlue: '#003D74',
  accentBlue: '#0080F8',
  yellow: '#E9C839',
  darkYellow: '#E3BC17',
  accentYellow: '#FFD51F',
  iconColor: '#676F77',
  white: '#FFFFFF',
  black: '#000000',
  aluminiumGray: '#EFEFEF',
  button: {
    color: '#0080F8',
    hover: '#0053A4',
    disabled: '#DDDDDD',
  },
  text: {
    body: '#323231',
    headings: '#676F77',
    link: '#0080F8',
    linkHover: '#0053A4',
  },
  monochrome: {
    darkestGrey: '#282B2E',
    darkerGrey: '#5B5B5B',
    darkGrey: '#777777',
    mediumGrey: '#AAAAAA',
    lightGrey: '#DDDDDD',
    lighterGrey: '#F2F2F2',
    lightestGrey: '#F8F8F8',
  },
}

// Demag doesn't have a dashboard, so all of this is unused for now.
// const primaryTile = {
//   backgroundColor: colors.monochrome.lighterGrey,
//   hoverBackgroundColor: colors.monochrome.lighterGrey,
//   descriptionBackgroundColor: colors.blue,
//   titleColor: colors.accentBlue,
//   titleHoverColor: colors.blue,
//   iconColor: colors.iconColor,
//   shadow: true,
//   showLinkIcon: true,
// }

// const secondaryTile = {
//   color: colors.white,
//   backgroundColor: colors.blue,
//   hoverBackgroundColor: colors.darkBlue,
// }

// const demoTile = {
//   color: colors.text.body,
//   backgroundColor: colors.aluminiumGray,
//   iconColor: colors.iconColor,
//   shadow: true,
// }

const storeUrl = isProduction ? 'https://shop.demagcranes.com' : 'https://shop-qa.demagcranes.com'
// const warrantyUrl = 'https://demag-ext-prod.launchpad.cfapps.eu20.hana.ondemand.com/site/fiori'

const themeConfig: Theme = {
  brand: 'demag',
  portalName: 'demagcranes.live',
  privacyPolicyLink: 'https://www.demagcranes.com/en/privacy-policy',
  cookiePolicyLink: 'https://www.demagcranes.com/en/privacy-policy',
  companyLink: 'https://www.demagcranes.com',
  siteTermsLink: '/terms-and-conditions/alpha-portal/latest.default.html',
  fontFamily: 'Produkt Web',
  fontSize: '15px',
  headerLogo: demagLogo,
  menuLogo: demagLogo,
  footerLogo: whiteDemagLogo,
  headerLogoSize: '200px',
  menuLogoSize: '200px',
  footerLogoSize: '150px',
  footerCopyrightId: 'demag_copyright',
  footerBackgroundColor: colors.darkBlue,
  primary: colors.blue,
  pageHeaderColor: colors.text.headings,
  headerBackgroundColor: colors.monochrome.darkestGrey,
  linkColor: colors.text.link,
  linkHoverColor: colors.text.linkHover,
  dashboardBanner: {
    borderColor: colors.monochrome.lighterGrey,
  },
  header: {
    color: '#9999',
    backgroundColor: colors.monochrome.darkestGrey,
  },
  menuLink: {
    color: colors.black,
    hoverBackgroundColor: colors.monochrome.lightestGrey,
    hoverColor: colors.blue,
    fontWeight: 'bold',
  },
  listItem: {
    titleColor: colors.black,
    descriptionColor: colors.black,
  },
  button: {
    primary: {
      color: colors.button.color,
      textColor: colors.white,
      hover: colors.button.hover,
    },
    secondary: {
      color: colors.monochrome.mediumGrey,
    },
  },
  storeUrl,
  modules: [
    // Main modules below:
    getBrandedModule('dashboard', {
      showInMenu: false,
    }),
    getBrandedModule('contact', {
      showInMenu: false,
    }),
    getBrandedModule('store', {
      to: storeUrl,
      title: 'demag_shop',
      showExternalIcon: false,
      menuItemIconProps: {
        icon: faHome,
      },
    }),
    getBrandedModule('businessDashboard', {
      title: 'demag_business_dashboard',
    }),
    getBrandedModule('troubleshooting', {
      title: 'demag_troubleshooting',
    }),
    getBrandedModule('eLearning'),
    ...(!isProduction
      ? [
        getBrandedModule('leadTimes', {
          title: 'lead_times',
        }),
      ]
      : []),
    // getBrandedModule('warranty', {
    //   to: warrantyUrl,
    //   external: true,
    // }),
  ],
}

export function loadTheme(): void {
  setFontSize()
  loadFontAndIcon()
  updatePageTitle()
}

function loadFontAndIcon() {
  const head = document.getElementsByTagName('head')[0]
  const fontLinkEl = document.createElement('link')
  fontLinkEl.rel = 'stylesheet'
  fontLinkEl.type = 'text/css'
  fontLinkEl.href = 'https://fonts.googleapis.com/css?family=Istok+Web:400,700&display=swap'
  head.appendChild(fontLinkEl)

  const iconLinkEl = document.createElement('link')
  iconLinkEl.rel = 'icon'
  iconLinkEl.type = 'image/x-icon'
  iconLinkEl.href = './icons/demag.ico'
  head.insertBefore(iconLinkEl, head.firstChild)
}

function setFontSize() {
  document.documentElement.style.fontSize = themeConfig.fontSize!
}

// FIXME: factor out and reuse.
function updatePageTitle() {
  const title = document.getElementsByTagName('title')[0]

  if (title) title.textContent = themeConfig.portalName!
}

export default themeConfig
