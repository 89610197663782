import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

import sha256 from '../utils/sha256'
import useAccessToken from './useAccessToken'
import useCustomerId from './useCustomerId'
import useDomain from './useDomain'
import { canLoadAdditionalScripts } from './useOneTrust'
import useOptanonAlertBoxClosedPolling from './useOptanonAlertBoxClosedPolling'

function useTagManager() {
  const isOptanonAlertBoxClosed = useOptanonAlertBoxClosedPolling()
  console.log('tagManager0 isOptanonAlertBoxClosed: ', isOptanonAlertBoxClosed)
  const { GA_ID, GTM } = useDomain()
  const { isAuthenticated, user } = useAuth0()
  const {
    status: accessTokenStatus,
    result: { getClaim },
  } = useAccessToken()
  const { result: customerIdResult } = useCustomerId()

  let userRole: string | undefined
  let userId: string | undefined // Hash of user email

  if (isAuthenticated && accessTokenStatus === 'success') {
    userRole = getClaim('cidm_role')
    userId = sha256(user!.email!)
  }

  useEffect(() => {
    if(isOptanonAlertBoxClosed && canLoadAdditionalScripts()) {
      // GA can live here until we're ready to remove it.
      ReactGA.initialize(GA_ID)
      TagManager.initialize(GTM)
    }
  }, [GA_ID, GTM, isOptanonAlertBoxClosed]) // GA_ID and GTM shouldn't change at runtime, so this will effectively run once.

  useEffect(() => {
    console.log('tagManager2 isOptanonAlertBoxClosed: ', isOptanonAlertBoxClosed)
    if (!canLoadAdditionalScripts() || !isOptanonAlertBoxClosed) {
      return
    }
    // We should always get the user role and ID, though the customer ID result is undefined
    // if we haven't actually called the API.
    if (![userRole, userId, customerIdResult].every(Boolean)) return

    // These can be empty strings.
    const customerId = customerIdResult!.customerId
    const siteId = customerIdResult!.siteId

    TagManager.dataLayer({
      dataLayer: { event: 'propsLoaded', customerId, siteId, userRole, userId },
    })
  }, [userRole, userId, customerIdResult, isOptanonAlertBoxClosed])
}

export default useTagManager
