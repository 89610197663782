import styled from '@emotion/styled'

export const MenuLinkStyles = styled.div<{ hovered?: boolean; disabled?: boolean }>`
  width: 100%;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.menuLink.fontWeight || 'normal'};

  a {
    color: ${({ theme }) => theme.menuLink.color};
    background-color: white;
    display: flex;
    align-items: center;
    padding: 12px 28px;
    text-decoration: none;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

    &:hover {
      color: ${({ theme, disabled }) =>
        disabled ? theme.menuLink.color : theme.menuLink.hoverColor};
      // Override activeStyle with !important.
      background-color: ${({ theme, disabled }) =>
        disabled ? 'white' : theme.menuLink.hoverBackgroundColor} !important;
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

      svg {
        color: ${({ theme, disabled }) =>
          disabled ? theme.menuLink.color : theme.menuLink.hoverColor};
      }
    }
  }
`

export const MenuDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e2dfdf;
  margin: 15px 0;
`

export const IconWrapper = styled.div`
  width: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  margin-right: 15px;
`
