import { useContext } from 'react'

import APIClientContext from '../context/APIClientContext'

export default function useAPIClient() {
  const context = useContext(APIClientContext)

  // istanbul ignore next
  if (context === undefined) {
    throw new Error('useAPIClient must be used within APIClientProvider')
  }

  return context
}
