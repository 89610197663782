import useMedia from './useMedia'
import { breakpoints } from '../constants/breakpoints'

const MOBILE = 'mobile'
const TABLET = 'tablet'
const DESKTOP = 'desktop'

type ScreenSize = 'mobile' | 'tablet' | 'desktop'

function useScreenSize() {
  const screenSize = useMedia(
    [`(min-width: ${breakpoints.desktop + 1}px)`, `(min-width: ${breakpoints.tablet + 1}px)`],
    [DESKTOP, TABLET],
    MOBILE,
  ) as ScreenSize

  return {
    isMobile: screenSize === MOBILE,
    isTablet: screenSize === TABLET,
    isDesktop: screenSize === DESKTOP,
    screenSize,
  }
}

export default useScreenSize
