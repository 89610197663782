import { useState, useEffect, useMemo } from 'react'

// from https://usehooks.com/
function useMedia(queries: string[], values: string[], defaultValue: string) {
  const mediaQueryLists = useMemo(() => queries.map((q) => window.matchMedia(q)), [queries])

  const getValue = () => {
    const matchedIndex = mediaQueryLists.findIndex((mql) => mql.matches)
    return typeof values[matchedIndex] !== 'undefined' ? values[matchedIndex] : defaultValue
  }

  const [value, setValue] = useState(getValue)

  useEffect(() => {
    const handler = () => setValue(getValue)
    mediaQueryLists.forEach((mql) => mql.addListener(handler))
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler))
  }, []) // eslint-disable-line

  return value
}

export default useMedia
