import React from 'react'

import domainConfig, { Auth0Config, APIConfig, GTMConfig } from '../../constants/domainConfig'

interface DomainState {
  auth0: Auth0Config
  API: APIConfig
  GA_ID: string
  GTM: GTMConfig
}

type Environments = 'development' | 'production' | 'test'
type DomainProviderProps = { children: React.ReactNode }

const DomainContext = React.createContext<DomainState | undefined>(undefined)

function getConfig(namespace: string) {
  let env: Environments = 'production'
  try {
    env = process.env.NODE_ENV
  } catch (e) {
    // assumed built production version which is not running in node
  }
  return domainConfig[env][window.location.hostname][namespace]
}

export function DomainProvider({ children }: DomainProviderProps) {
  const domainProps: DomainState = {
    auth0: getConfig('auth0'),
    API: getConfig('API'),
    GA_ID: getConfig('GA_ID'),
    GTM: getConfig('GTM'),
  }

  return <DomainContext.Provider value={domainProps}>{children}</DomainContext.Provider>
}

export default DomainContext
