import './utils/polyfills'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import 'normalize.css'

import i18n from './utils/i18n'
import history from './utils/history'
import { ApolloClientProvider } from './context/ApolloClientContext'
import { DomainProvider } from './context/DomainContext'
import { ThemeProvider } from './context/ThemeContext'
import { AuthProvider } from './context/AuthContext'
import { UserMetadataProvider } from './context/UserMetadataContext'
import { APIClientProvider } from './context/APIClientContext'
import { KeyProvider } from './context/KeyContext'
import { PermissionProvider } from './context/PermissionContext'
import { CustomerIdProvider } from './context/CustomerIdContext'
import { BreadcrumbProvider } from './context/BreadcrumbContext'
import { TokenProvider } from './context/TokenContext'
import App from './App'
import './index.css'

if (process.env.NODE_ENV !== 'production') {
  const axe = require('react-axe')
  axe(React, ReactDOM, 1000, {
    rules: [
      {
        id: 'region',
        enabled: false,
      },
    ],
  })
}

history.listen((location, action) => {
  if (action === 'PUSH') {
    window.scrollTo(0, 0)
  }
})

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <DomainProvider>
      <ThemeProvider>
        <AuthProvider>
          <UserMetadataProvider>
            <APIClientProvider>
              <KeyProvider>
                <PermissionProvider>
                  <TokenProvider>
                    <ApolloClientProvider>
                      <CustomerIdProvider>
                        <I18nextProvider i18n={i18n}>
                          <BreadcrumbProvider>
                            <Router history={history}>
                              <ReactTooltip
                                effect="solid"
                                className="react-tooltip-custom"
                                backgroundColor="black"
                              />
                              <App />
                              <div id="modal-root" />
                            </Router>
                          </BreadcrumbProvider>
                        </I18nextProvider>
                      </CustomerIdProvider>
                    </ApolloClientProvider>
                  </TokenProvider>
                </PermissionProvider>
              </KeyProvider>
            </APIClientProvider>
          </UserMetadataProvider>
        </AuthProvider>
      </ThemeProvider>
    </DomainProvider>
  </Suspense>,
  document.getElementById('root'),
)
