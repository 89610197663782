import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

type TokenContextType = {
  authToken: string | null
}

const TokenContext = createContext<TokenContextType | undefined>(undefined)

type TokenProviderProps = {
  children: ReactNode
}

export const TokenProvider: React.FC<TokenProviderProps> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const [authToken, setAuthToken] = useState<string | null>(null)

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently()
        setAuthToken(token)
      } catch (error) {
        console.error('Error getting token:', error)
        setAuthToken(null)
      }
    }

    getToken()
  }, [getAccessTokenSilently])

  return <TokenContext.Provider value={{ authToken }}>{children}</TokenContext.Provider>
}

export const useToken = (): TokenContextType => {
  const context = useContext(TokenContext)
  if (context === undefined) {
    throw new Error('useToken must be used within a TokenProvider')
  }
  return context
}
