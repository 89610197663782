import styled from '@emotion/styled'

const HeaderText = styled.h1`
  color: ${({ theme }) => theme.pageHeaderColor || 'black'};
  font-size: 2rem;
  font-weight: bold;
  margin-top: 3rem;
  min-height: 2rem;
`

export default HeaderText
