import styled from '@emotion/styled'

export const FooterStyles = styled.footer<{ backgroundImage?: string }>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 20px 35px;
  background: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage}) center/cover` : 'none'};
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.footerBackgroundColor || '#333333'};
  min-height: 120px;

  * {
    color: ${({ theme }) => theme.footerColor || 'white'};
  }

  order: 1;

  p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  img {
    flex: 0 0 auto; // IE 11 compatibility
    object-fit: scale-down; // Firefox compatibility
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 20px;

    & > div {
      margin: 10px 0;
    }
  }
`

export const Links = styled.div`
  display: flex;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 30px;
  }

  a {
    display: block;
    text-decoration: none;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    order: -1;
  }
`
