import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { DrawerMenu as Drawer } from '@kc/alphabrands-component-library'

import useModules from '../../hooks/useModules'
import { Module } from '../../themes'
import Accordion from '../Accordion'
import {
  DrawerMenuLink,
  divider,
  collapse,
  DrawerMenuLinkProps,
  MenuItemType,
  MenuItem,
} from './DrawerMenuItems'
import { MenuDivider, MenuLinkStyles } from './DrawerMenuItems.styles'
import { BrandImagePlaceholder, BrandImage } from './DrawerMenu.styles'

const getMenuLinkProps = (m?: Module): DrawerMenuLinkProps => {
  if (!m) throw new Error('Missing module config in DrawerMenu')
  return {
    type: MenuItemType.Link,
    to: m.to,
    title: m.menuItemTitle!,
    disabled: m.disabled,
    external: m.external,
    showExternalIcon: m.showExternalIcon,
    IconComponent: m.IconComponent,
    menuItemIconProps: m.menuItemIconProps,
    modalContent: m.modalContent,
  }
}

const DrawerMenu = () => {
  const { brand, menuLogo, menuLogoSize } = useTheme()
  const { t } = useTranslation()
  const modules = useModules()

  const menuModules = modules.filter((m) => !!m.showInMenu)
  const dashboardModule = menuModules.find((m) => m.name === 'dashboard')
  const contactModule = menuModules.find((m) => m.name === 'contact')
  const restModules = menuModules.filter((m) => !['dashboard', 'contact'].includes(m.name))
  const demoModules = restModules.filter((m) => !!m.demo)
  const mainModules = restModules.filter((m) => !m.demo)
  const internalModules = mainModules.filter((m) => !(m.showExternalIcon ?? m.external))
  const externalModules = mainModules.filter((m) => !!(m.showExternalIcon ?? m.external))

  const getMenuItems = () => {
    const internalMenuItems = internalModules.map(getMenuLinkProps)
    const externalMenuItems = externalModules.map(getMenuLinkProps)
    const demoMenuItems = demoModules.length
      ? [divider, collapse(demoModules.map(getMenuLinkProps))]
      : []

    switch (brand) {
      case 'rm':
        return [
          getMenuLinkProps(dashboardModule),
          ...externalMenuItems,
          getMenuLinkProps(contactModule),
          ...internalMenuItems,
          ...demoMenuItems,
        ]
      case 'swf':
        return [
          getMenuLinkProps(dashboardModule),
          ...internalMenuItems,
          divider,
          ...externalMenuItems,
          divider,
          getMenuLinkProps(contactModule),
        ]
      case 'demag': {
        return [...internalMenuItems, ...externalMenuItems]
      }
      default:
        return [
          getMenuLinkProps(dashboardModule),
          ...internalMenuItems,
          ...demoMenuItems,
          divider,
          ...externalMenuItems,
          divider,
          getMenuLinkProps(contactModule),
        ]
    }
  }

  const renderMenuItem = (item: MenuItem, i: number) => {
    switch (item.type) {
      case MenuItemType.Divider:
        return <MenuDivider key={i} />
      case MenuItemType.Link:
        return <DrawerMenuLink key={i} {...item} />
      case MenuItemType.Collapse:
        return (
          <MenuLinkStyles key={i}>
            <Accordion
              headerContent={
                <span>
                  {t(brand === 'rm' ? 'concept_validation_heading_rm' : 'concept_validation')}
                </span>
              }
            >
              {item.items.map((collapseItem, x) => (
                <DrawerMenuLink key={x} {...collapseItem} />
              ))}
            </Accordion>
          </MenuLinkStyles>
        )
    }
  }

  const menuItems = getMenuItems()

  return (
    <Drawer>
      <Drawer.Button>
        <Icon icon={faBars} size="2x" color="white" />
      </Drawer.Button>
      <Drawer.Drawer>
        {menuLogo ? (
          <BrandImage src={menuLogo} alt={brand} style={{ width: menuLogoSize }} />
        ) : (
          <BrandImagePlaceholder />
        )}
        {menuItems.map(renderMenuItem)}
      </Drawer.Drawer>
    </Drawer>
  )
}

export default DrawerMenu
