import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Popup } from '@kc/alphabrands-component-library'
import { faCaretDown, faCheck, faGlobeAfrica as faGlobe } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { LanguageSelectorButton, LanguageList, Language } from './LanguageSelector.styles'
import useScreenSize from '../../hooks/useScreenSize'
import { Language as LanguageType } from '../../utils/i18n'
import PopupMobileHeader from '../PopupMobileHeader'

interface LanguageSelectorProps {
  activeLanguage: string
  languages: LanguageType[]
  onLanguageChange: (lang: string) => void
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  activeLanguage,
  languages,
  onLanguageChange,
}) => {
  const changeLang = (lang: string) => () => {
    onLanguageChange(lang)
    document.documentElement.lang = lang
  }
  const { isTablet, isDesktop } = useScreenSize()
  const isSmallDevices = !isTablet && !isDesktop
  const { t } = useTranslation()
  const language = languages.find((l) => l.langCode === activeLanguage)!
  const triggerText =
    language.langSelect === 'language_select_chinese' || !isSmallDevices
      ? t(language.langSelect)
      : language.langCode.toLocaleUpperCase()

  return (
    <Popup
      alignment="right"
      nodeId="language-selector"
      positionStyles={isSmallDevices ? { position: 'fixed', top: 0, left: 0, right: 0 } : null}
      relativePosition={{ top: 20, left: 5 }}
    >
      <Popup.Trigger>
        <LanguageSelectorButton aria-label="Select language">
          {!isSmallDevices && <Icon icon={faGlobe} />}
          <span>{triggerText}</span>
          <Icon icon={faCaretDown} size="lg" style={{ marginLeft: '5px', marginTop: '-3px' }} />
        </LanguageSelectorButton>
      </Popup.Trigger>
      <Popup.Content>
        {({ closeDropdown }) => (
          <>
            {isSmallDevices && (
              <PopupMobileHeader headerText={t('select_language')} onClose={closeDropdown} />
            )}
            <LanguageList onClick={closeDropdown}>
              {languages.map(({ langCode, langSelect }) => (
                <Language
                  key={langCode}
                  onClick={changeLang(langCode)}
                  active={langCode === activeLanguage}
                >
                  <span>{t(langSelect)}</span>
                  {langCode === activeLanguage && (
                    <Icon icon={faCheck} size="sm" style={{ marginLeft: '5px' }} />
                  )}
                </Language>
              ))}
            </LanguageList>
          </>
        )}
      </Popup.Content>
    </Popup>
  )
}

export default LanguageSelector
