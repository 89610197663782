import { useContext } from 'react'

import PermissionContext from '../context/PermissionContext'

export default function usePermission() {
  const context = useContext(PermissionContext)

  // istanbul ignore next
  if (context === undefined) {
    throw new Error('usePermission must be used within PermissionProvider')
  }

  return context
}
