import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-async-hook'
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'

import { News } from '../../api/types'
import { toStrShortDateFormat } from '../../utils/dates'
import useAPIClient from '../../hooks/useAPIClient'
import ExternalLink from '../../components/ExternalLink'
import {
  WidgetStyles,
  Header,
  Title,
  NewsFeedContainer,
  NewsFeedItem,
  ShowMore,
  PaddedBox,
} from './NewsWidget.styles'

type NewsWidgetProps = {
  title: string
  to: string
  icon?: JSX.Element
  color?: string
  titleColor?: string
  titleHoverColor?: string
  iconColor?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  shadow?: boolean
}

const NewsWidget: React.FC<NewsWidgetProps> = ({ title, icon, titleColor, ...tileProps }) => {
  const { t, i18n } = useTranslation()
  const apiClient = useAPIClient()

  const asyncNews = useAsync<News[]>(async () => {
    const { data } = await apiClient.listNews()
    return data
  }, [])

  // Sort by createdAt desc. Limit at 3.
  const sortedNews =
    asyncNews.result
      ?.filter((news) => (news.showToAll || (news.languages && news.languages.includes(i18n.language)))).sort(
        (a, b) => new Date(b.publishedAt ?? '').getTime() - new Date(a.publishedAt ?? '').getTime(),
      )
      .filter((news) => moment().diff(news.publishedAt,'minutes') >= 0)
      .slice(0, 3) ?? []

  return (
    <WidgetStyles data-testid="news-widget" {...tileProps}>
      <RouterLink to={tileProps.to}>
        <Header>
          {icon}
          <Title color={titleColor}>{title}</Title>
        </Header>
      </RouterLink>
      <NewsFeedContainer color="#000000">
        {asyncNews.loading && <PaddedBox>{t('loading_news')}</PaddedBox>}
        {asyncNews.error && <PaddedBox>{t('error_loading_news')}</PaddedBox>}
        {asyncNews.result &&
          (sortedNews.length ? (
            sortedNews.map((news, idx) => (
              <ExternalLink to={news.url} key={news.id}>
                <NewsFeedItem style={{ border: idx === 2 ? 'none' : undefined }}>
                  <span>
                    {news.title}
                    <Icon
                      icon={faExternalLinkAlt}
                      size="xs"
                      data-testid="external-icon"
                      style={{ marginLeft: '5px', color: '#000000' }}
                    />
                  </span>
                  <span style={{ marginLeft: '10px', flexShrink: 0 }}>
                    {/* Strip the year */}
                    {toStrShortDateFormat(news.publishedAt).split(',')[0]}
                  </span>
                </NewsFeedItem>
              </ExternalLink>
            ))
          ) : (
            <PaddedBox>{t('no_news')}</PaddedBox>
          ))}
      </NewsFeedContainer>
      <ShowMore color={titleColor}>
        <RouterLink to={tileProps.to}>{t('show_more_news')}</RouterLink>
      </ShowMore>
    </WidgetStyles>
  )
}

export default NewsWidget
