import React from 'react'

import Icon, { IconProps } from './Icon'

const PriceListIcon: React.FC<IconProps> = (props) => (
  <Icon
    {...props}
    viewBox="0 0 130 130"
    path="M91.72,29.38H76.88a11.88,11.88,0,1,0-23.75,0H38.28a8.89,8.89,0,0,0-8.9,8.9v65.31a8.9,8.9,0,0,0,8.9,8.91H91.72a8.91,8.91,0,0,0,8.91-8.91V38.28A8.9,8.9,0,0,0,91.72,29.38ZM65,24.92a4.46,4.46,0,1,1-4.45,4.46A4.46,4.46,0,0,1,65,24.92Zm13.47,73a22.24,22.24,0,0,1-5.46.7A18.69,18.69,0,0,1,54.49,84.7H51.8a1.07,1.07,0,0,1-1.08-1.07V81.1A1.07,1.07,0,0,1,51.8,80h1.9a28.1,28.1,0,0,1,0-3.78H51.8a1.07,1.07,0,0,1-1.08-1.07V72.53a1.08,1.08,0,0,1,1.08-1.08h3A19.09,19.09,0,0,1,73,58.6a26.51,26.51,0,0,1,4.71.46,1.07,1.07,0,0,1,.83,1.33l-1.07,4a1.08,1.08,0,0,1-1.25.77,17.47,17.47,0,0,0-3.38-.37,11.22,11.22,0,0,0-10.5,6.69H74.69a1.08,1.08,0,0,1,1,1.31l-.58,2.65a1.11,1.11,0,0,1-1,.84H61A18.36,18.36,0,0,0,61,80H73a1.06,1.06,0,0,1,1,1.3l-.57,2.54a1.11,1.11,0,0,1-1,.83H62.28a11.49,11.49,0,0,0,10.84,7.63,18.71,18.71,0,0,0,4.06-.48,1.07,1.07,0,0,1,1.29.83l.79,4A1.08,1.08,0,0,1,78.47,97.9Zm4.34-51.83a1.12,1.12,0,0,1-1.11,1.12H48.3a1.12,1.12,0,0,1-1.11-1.12V42.36a1.11,1.11,0,0,1,1.11-1.11H81.7a1.11,1.11,0,0,1,1.11,1.11Z"
  />
)

export default PriceListIcon
