import React from 'react'
import styled from '@emotion/styled'

import { mq } from '../../constants/breakpoints'

const Button = styled.button`
  display: flex;
  align-items: center;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;
  cursor: pointer;
  color: white;

  p {
    margin: 0;
  }

  & > * {
    margin-left: 25px;
    align-self: center;
  }

  svg {
    margin-right: 12px;

    ${mq.xs_mobile} {
      margin-left: 12px;
    }
  }
`

export const DropdownButton = React.forwardRef<HTMLButtonElement, { children: React.ReactElement }>(
  (props, ref) => (
    <Button ref={ref} {...props}>
      {props.children}
    </Button>
  ),
)
DropdownButton.displayName = 'DropdownButton'

export const AccountTextPart = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  font-size: 0.8rem;

  & > p:nth-of-type(1) {
    font-size: 0.8rem;
  }

  & > p:nth-of-type(2) {
    font-size: 0.8rem;
    font-weight: bold;
  }
`

export const DropdownContent = styled.div`
  position: relative;
  padding: 10px 0 0;
  border: 1px solid #707070;
  background-color: white;
  border-radius: 3px;
`

export const Name = styled.p`
  font-size: 1rem;
  margin: 5px 0;
`

export const MenuItem = styled.p`
  font-size: 1rem;
  margin: 0;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: lightgrey;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e2dfdf;
  margin: 10px 0;
`
