import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAsync, UseAsyncReturn } from 'react-async-hook'
import { useAuth0 } from '@auth0/auth0-react'
import { useTheme } from '@emotion/react'

import useDomain from '../../hooks/useDomain'
import { getEnv } from '../../themes/helpers'

const isDevelopment = getEnv() === 'dev'
const consentMessage =
  "(This only shows in developement) Using the Auth0 Management API requires a separate user consent. Check the bottom of UserMetadataContext.tsx for more info. If you're not on localhost, please contact the ACP dev team."

// This is the only thing we care about for now.
type UserMetadata = {
  nps_last_shown_at?: Date
}

export type UserMetadataState = {
  asyncUserMetadata: UseAsyncReturn<UserMetadata | undefined>
  updateUserMetadata: (data: UserMetadata) => Promise<void>
}

const UserMetadataContext = React.createContext<UserMetadataState | undefined>(undefined)

export function UserMetadataProvider({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0()
  const { brand } = useTheme()
  const {
    auth0: { domain, managementAudience },
  } = useDomain()
  const [showConsentAlert, setShowConsentAlert] = useState(false)

  const accessTokenConfig = {
    audience: managementAudience,
    scope: 'read:current_user update:current_user_metadata',
  }

  const asyncUserMetadata = useAsync<UserMetadata | undefined>(async () => {
    // istanbul ignore next
    if (!isAuthenticated || brand === 'demag') return

    const accessToken = await getAccessTokenSilently(accessTokenConfig).catch((e) => {
      console.error(e)
      if (isDevelopment && /consent/i.test(e)) setShowConsentAlert(true)
    })

    // istanbul ignore next
    if (!accessToken) return

    // Can't be entirely sure metadata is always defined in Auth0.
    // Dates aren't converted automatically from JSON.
    const { data } = await axios.get<{ user_metadata?: { nps_last_shown_at?: string } }>(
      `https://${domain}/api/v2/users/${user!.sub}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      },
    )

    return {
      ...data.user_metadata,
      nps_last_shown_at: data.user_metadata?.nps_last_shown_at
        ? new Date(data.user_metadata.nps_last_shown_at)
        : undefined,
    }
  }, [isAuthenticated])

  const updateUserMetadata = async (userMetadata: UserMetadata) => {
    // istanbul ignore next
    if (!isAuthenticated || brand === 'demag') return

    const accessToken = await getAccessTokenSilently(accessTokenConfig).catch(console.error)

    // istanbul ignore next
    if (!accessToken) return

    const data = { user_metadata: userMetadata }
    await axios.patch(`https://${domain}/api/v2/users/${user!.sub}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })

    // Re-fetch metadata.
    asyncUserMetadata.execute()
  }

  // Show at most once per session.
  useEffect(() => {
    if (showConsentAlert) alert(consentMessage)
  }, [showConsentAlert])

  // istanbul ignore next
  if (asyncUserMetadata.status === 'error') {
    console.error('[UserMetadataProvider]', asyncUserMetadata.error)
  }

  const value = {
    asyncUserMetadata,
    updateUserMetadata,
  }

  return <UserMetadataContext.Provider value={value}>{children}</UserMetadataContext.Provider>
}

export default UserMetadataContext

/**
 * Getting an access token for the Management API requires a separate user consent.
 * This can be skipped for first-party apps (which this is) in an environment where localhost
 * isn't among the callback URLs. More here:
 * https://auth0.com/docs/authorization/user-consent-and-third-party-applications#skip-consent-for-first-party-applications
 * This means the development Auth0 tenant requires an explicit consent and the easiest way
 * to get it is to log in with the Management API audience and go through the consent dialog.
 * Replace the audince and scope in AuthContext.tsx to do so. Only needs to be done once per user.
 */
