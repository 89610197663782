import React from 'react'

export interface IconProps {
  color?: string
  width?: string
  height?: string
}

interface SvgProps extends IconProps {
  path: string
  viewBox: string
}

const Icon: React.FC<SvgProps> = ({ viewBox, path, width = '26px', height }) => (
  <svg
    viewBox={viewBox}
    style={{
      display: 'block',
      width,
      height: height || width,
    }}
  >
    <path d={path} fill="currentColor" />
  </svg>
)

export default Icon
